import { FC, PropsWithChildren, useContext, useState } from 'react';
import { useEventListener } from 'usehooks-ts';
import { useLocation, useNavigate } from 'react-router-dom';
import { RolePermissionsContext } from '@/contexts/rolePermissions/context';
import { hasPermissionsToElement } from '@/components/PermissionsChecker/utils';
import { ROUTE_PATHS } from '@/router/constants';
import { PreferencesContext } from '../preferences/context';
import { THEME_MODE } from '../theme/constants';
import { UIStateContext } from './context';
import { PanelHotkey, Panel, UIState, PANEL } from './types';

export const KEY_EVENT_TAGS_BLACKLIST = ['INPUT', 'TEXTAREA'];
export const KEY_EVENT_INPUT_TYPE_WHITELIST = ['checkbox', 'radio'];
const KEY_DOWN_BLACKLIST = [{ key: 'c', ctrlKey: true, metaKey: true }];

export const PANEL_HOTKEYS: PanelHotkey[] = [
  { key: 'c', panel: PANEL.CHECKLIST },
  { key: 'f', panel: PANEL.FILTERS },
  { key: 'p', panel: PANEL.PREFERENCES },
  { key: 'm', panel: PANEL.DATA_REVIEW },
];

const isKeyDownBlacklisted = (e: KeyboardEvent) =>
  KEY_DOWN_BLACKLIST.some(
    ({ key, ctrlKey, metaKey }) =>
      key === e.key && ((ctrlKey && e.ctrlKey) || (metaKey && e.metaKey)),
  );

export const UiStateProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    themeMode,
    actions: { setThemeMode },
  } = useContext(PreferencesContext);
  const { permissions } = useContext(RolePermissionsContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openedPanel, setOpenedPanel] = useState<Panel>(null);
  const [isFixtureInfoVisible, setFixtureInfoVisible] = useState(false);
  const [isDataCollectorsVisible, setIsDataCollectorsVisible] = useState(false);
  const [isScoreboardVisible, setIsScoreboardVisible] = useState(true);
  const [hotkeysDisabled, setHotkeysDisabled] = useState<boolean>(false);
  const [showRowEditPopup, setShowRowEditPopup] = useState(false);
  const [assignCollection, setAssignCollection] = useState(false);
  const [unAssignCollection, setUnAssignCollection] = useState(false);
  const [goToCollection, setGoToCollection] = useState(false);
  const [isSlaBreachActionSelected, setIsSlaBreachActionSelected] =
    useState(false);
  const [showRowLatency, setShowRowLatency] = useState(false);
  const hasAccessToSuperAdminView = hasPermissionsToElement(
    'SuperAdminPage',
    permissions,
  );

  const shouldIgnoreEvent = (e: KeyboardEvent) => {
    const isBlacklistTag =
      e.target &&
      KEY_EVENT_TAGS_BLACKLIST.includes((e.target as HTMLElement).tagName) &&
      (e.target as HTMLInputElement).type &&
      !KEY_EVENT_INPUT_TYPE_WHITELIST.includes(
        (e.target as HTMLInputElement).type,
      );

    return isBlacklistTag || hotkeysDisabled;
  };

  const setOpenedPanelWithToggle: UIState['setOpenedPanel'] = (panel) =>
    setOpenedPanel(openedPanel === panel ? null : panel);

  const onKeyPress = (e: KeyboardEvent) => {
    if (shouldIgnoreEvent(e) || isKeyDownBlacklisted(e)) {
      return;
    }
    const panelHotkey = PANEL_HOTKEYS.find(({ key }) => key === e.key);

    if (
      e.key === 'i' &&
      hasPermissionsToElement(
        'FixturePage.HeaderPanel.FixtureInfo',
        permissions,
      )
    ) {
      setFixtureInfoVisible(!isFixtureInfoVisible);
    }
    if (
      e.key === 'd' &&
      hasPermissionsToElement(
        'FixturePage.HeaderPanel.DataCollectors',
        permissions,
      )
    ) {
      setIsDataCollectorsVisible(!isDataCollectorsVisible);
    }
    if (e.key === 't') {
      setThemeMode(
        themeMode === THEME_MODE.DARK ? THEME_MODE.LIGHT : THEME_MODE.DARK,
      );
    }
    if (e.key === 'e') {
      if (!showRowEditPopup) {
        setShowRowEditPopup(!showRowEditPopup);
      }
    }
    if (e.key === 'a') {
      if (!assignCollection) {
        setAssignCollection(!assignCollection);
      }
    }
    if (e.key === 'u') {
      if (!unAssignCollection) {
        setUnAssignCollection(!unAssignCollection);
      }
    }
    if (e.key === 'g') {
      if (!goToCollection) {
        setGoToCollection(!goToCollection);
      }
    }
    if (e.key === 'a') {
      if (!assignCollection) {
        setAssignCollection(!assignCollection);
      }
    }
    if (e.key === 'l') {
      if (!showRowLatency) {
        setShowRowLatency(!showRowLatency);
      }
    }
    if (
      e.key === 's' &&
      hasPermissionsToElement('FixturePage.ScoreboardPanel', permissions)
    ) {
      setIsScoreboardVisible(!isScoreboardVisible);
    }

    if (e.key === 'v') {
      if (pathname === ROUTE_PATHS.SUPER_ADMIN || !hasAccessToSuperAdminView) {
        navigate(ROUTE_PATHS.HOME);
      } else {
        navigate(ROUTE_PATHS.SUPER_ADMIN);
      }
    }

    if (e.key === '/' && (e.metaKey || e.ctrlKey)) {
      setOpenedPanelWithToggle(PANEL.KEYBOARD_SHORTCUTS);
    }
    if (panelHotkey) {
      return setOpenedPanelWithToggle(panelHotkey.panel);
    }
  };

  useEventListener('keydown', onKeyPress);

  const providerValue: UIState = {
    openedPanel,
    setOpenedPanel: setOpenedPanelWithToggle,
    isFixtureInfoVisible,
    setFixtureInfoVisible,
    setHotkeysDisabled,
    isDataCollectorsVisible,
    setIsDataCollectorsVisible,
    showRowEditPopup,
    setShowRowEditPopup,
    isSlaBreachActionSelected,
    setIsSlaBreachActionSelected,
    showRowLatency,
    setShowRowLatency,
    setIsScoreboardVisible,
    isScoreboardVisible,
    assignCollection,
    setAssignCollection,
    goToCollection,
    unAssignCollection,
    setGoToCollection,
    setUnAssignCollection,
  };
  return (
    <UIStateContext.Provider value={providerValue}>
      {children}
    </UIStateContext.Provider>
  );
};
