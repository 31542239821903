import { OptionsWithExtraProps } from 'notistack';
import { Theme } from '@mui/material';
import { FixtureAction, FixtureSupervisorCall } from '@/service/types';
import {
  NotificationsFilterType,
  PlayersLeftVisualNotification,
  VISUAL_NOTIFICATIONS_PROPERTIES,
  VisualNotificationMember,
  VisualNotifications,
} from '@/contexts/visualNotifications/types';
import {
  DEFAULT_VISUAL_NOTIFICATIONS_SNACKBAR_OPTIONS,
  VISUAL_NOTIFICATIONS_FILTERS,
} from '@/contexts/visualNotifications/constants';
import {
  FIXTURE_ACTION_TYPE,
  MATCH_ACTION_SEND_TYPE,
  SPORT_ID,
} from '@/service/constants';

interface FindShowAndHideActionsProps {
  showFilters: NotificationsFilterType[];
  hideFilters: NotificationsFilterType[];
  fixtureActions: FixtureAction[];
}

export const generateVisualNotificationsFilters = (sportId: number) => {
  const isVolleyball =
    sportId === SPORT_ID.VOLLEYBALL || sportId === SPORT_ID.BEACH_VOLLEYBALL;

  if (isVolleyball) {
    return VISUAL_NOTIFICATIONS_FILTERS;
  } else {
    return {
      ...VISUAL_NOTIFICATIONS_FILTERS,
      POSSIBLE_VAR: {
        ...VISUAL_NOTIFICATIONS_FILTERS.POSSIBLE_VAR,
        showFilters: [
          ...VISUAL_NOTIFICATIONS_FILTERS.POSSIBLE_VAR.showFilters,
          {
            actionType: FIXTURE_ACTION_TYPE.VIDEO_CHECK_UNDERWAY,
            sendType: MATCH_ACTION_SEND_TYPE.DELETED,
          },
        ],
      },
    };
  }
};

export const generateInitialVisualNotificationsState = (
  fixtureActions: FixtureAction[],
  supervisorCall: FixtureSupervisorCall | null,
  visualNotificationsFilters: typeof VISUAL_NOTIFICATIONS_FILTERS,
) => {
  return {
    flagOnField: findShowAndHideActions({
      ...visualNotificationsFilters.FLAG_ON_FIELD,
      fixtureActions,
    }),
    possibleVar: findShowAndHideActions({
      ...visualNotificationsFilters.POSSIBLE_VAR,
      fixtureActions,
    }),
    supervisorCall: {
      showSnack: -1,
      hideSnack: -1,
      isActive: !supervisorCall ? false : !supervisorCall.acknowledged,
    },
    marketOpened: findShowAndHideActions({
      ...visualNotificationsFilters.MARKET_OPEN,
      fixtureActions,
    }),
    marketClosed: findShowAndHideActions({
      ...visualNotificationsFilters.MARKET_CLOSE,
      fixtureActions,
    }),
    varUnderway: findShowAndHideActions({
      ...visualNotificationsFilters.VAR_UNDERWAY,
      fixtureActions,
    }),
    playStopped: findShowAndHideActions({
      ...visualNotificationsFilters.PLAY_STOPPED,
      fixtureActions,
    }),
    startTime: { showSnack: -1, hideSnack: -1, isActive: false },
  };
};

export const findShowAndHideActions = ({
  showFilters,
  hideFilters,
  fixtureActions,
}: FindShowAndHideActionsProps) => {
  const showAction = fixtureActions.find((fixtureAction) =>
    generateFindActionRules(showFilters, fixtureAction),
  )?.fixtureSeqNum;

  const hideAction = fixtureActions.find((fixtureAction) =>
    generateFindActionRules(hideFilters, fixtureAction),
  )?.fixtureSeqNum;

  return {
    showSnack: showAction ? showAction : -1,
    hideSnack: hideAction ? hideAction : -1,
    isActive: (showAction || -1) > (hideAction || -1),
  };
};

export const generateFindActionRules = (
  filters: NotificationsFilterType[],
  fixtureAction: FixtureAction,
) =>
  filters.find((filter) =>
    !filter.sendType
      ? filter.actionType === fixtureAction.fixtureActionTypeId
      : filter.actionType === fixtureAction.fixtureActionTypeId &&
        filter.sendType === fixtureAction.sendTypeId,
  );

export const getPlayersLeft = ({
  showFilters,
  hideFilters,
  fixtureActions,
}: FindShowAndHideActionsProps) => {
  const playersLeft = filterActionsByActiontypeId(fixtureActions, showFilters);
  const playersReturned = filterActionsByActiontypeId(
    fixtureActions,
    hideFilters,
  );

  const uniquePlayersLeft: PlayersLeftVisualNotification[] = [];
  const addedPlayerIds: string[] = [];

  playersLeft?.forEach((playerLeft) => {
    if (playerLeft.playerId && !addedPlayerIds.includes(playerLeft.playerId)) {
      const hasReturnedPlayer = playersReturned?.find(
        (playerReturned) => playerReturned.playerId === playerLeft.playerId,
      );
      if (
        !hasReturnedPlayer ||
        playerLeft.fixtureSeqNum > hasReturnedPlayer.fixtureSeqNum
      ) {
        uniquePlayersLeft.push({
          playerId: playerLeft.playerId,
          teamId: playerLeft.teamId,
          fixtureSeqNum: playerLeft.fixtureSeqNum,
        });
        addedPlayerIds.push(playerLeft.playerId);
      }
    }
  });

  return uniquePlayersLeft;
};

export const filterActionsByActiontypeId = (
  actions: FixtureAction[],
  filters: NotificationsFilterType[],
) => {
  if (!actions) return;

  const filteredActions = actions.filter((action) => {
    return filters.some((filter) =>
      !filter.sendType
        ? filter.actionType === action.fixtureActionTypeId
        : filter.actionType === action.fixtureActionTypeId &&
          filter.sendType === action.sendTypeId,
    );
  });

  return filterUniqueActions(filteredActions);
};

export const filterUniqueActions = (actions: FixtureAction[]) => {
  const actionMap = actions.reduce<{ [key: string]: FixtureAction }>(
    (acc, action) => {
      if (
        !acc[action.actionId] ||
        acc[action.actionId].fixtureSeqNum < action.fixtureSeqNum
      ) {
        acc[action.actionId] = action;
      }
      return acc;
    },
    {},
  );

  return Object.values(actionMap);
};

export const generateNewVisualNotificationsResults = (
  newAction: VisualNotificationMember,
  previousAction: VisualNotificationMember,
): VisualNotificationMember => {
  const newSnack = {
    showSnack:
      newAction.showSnack > previousAction.showSnack
        ? newAction.showSnack
        : previousAction.showSnack,
    hideSnack:
      newAction.hideSnack > previousAction.hideSnack
        ? newAction.hideSnack
        : previousAction.hideSnack,
  };

  return {
    ...newSnack,
    isActive: newSnack.showSnack > newSnack.hideSnack,
  };
};

export const generateSnackbarOptions = (
  type: keyof VisualNotifications,
  theme: Theme,
  options?: OptionsWithExtraProps<'info'>,
): OptionsWithExtraProps<'info'> => ({
  ...DEFAULT_VISUAL_NOTIFICATIONS_SNACKBAR_OPTIONS,
  key: type,
  style: {
    backgroundColor: theme.palette.snackbarColors[type].backgroundColor,
    color: theme.palette.getContrastText(
      theme.palette.snackbarColors[type].backgroundColor,
    ),
    minWidth:
      type === VISUAL_NOTIFICATIONS_PROPERTIES.START_TIME ? '7rem' : '25rem',
  },
  ...options,
});
