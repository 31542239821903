import Toolbar from '@mui/material/Toolbar';
import { ButtonGroup, Chip, Divider, Stack } from '@mui/material';
import { useContext } from 'react';
import { RefreshIntervalButton } from '@/components/FixturesTable/common/RefreshIntervalButton';
import { CheckboxActionMenu } from '@/components/FixturesTable/CheckboxActionMenu/CheckboxActionMenu';
import { CheckboxAssignMeItem } from '@/components/FixturesTable/CheckboxActionMenu/CheckboxMenuItems/CheckboxAssignMeItem';
import { CheckboxUnassignMeItem } from '@/components/FixturesTable/CheckboxActionMenu/CheckboxMenuItems/CheckboxUnassignMeItem';
import { FixturesTableContext } from '@/components/FixturesTable/context/FixturesTableContext';
import { SearchField } from '@/components/FixturesTable/common/SearchField';
import { COMMON_STRING } from '@/constants/dictionary';
import { FixturesFilterButtons } from '@/components/FixturesTable/FixturesFilters/FixturesFilterButtons';
import { AnyPrimitive } from '@/types/common';
import { FixturesFilter } from '@/components/FixturesTable/FixturesFilters/types';

export const REFRESH_INTERVALS = [10000, 30000, 60000, 0];

export const FixturesTableToolbar = () => {
  const {
    checkboxes: { selectedFixtures, handleDeselectAllFixtures },
    refresh: { refreshInterval, setRefreshInterval },
    actions: { assignMeHandler, unAssignMeHandler },
    filters: { ftSearch, setFtSearch, filtersList, onFilterRemove },
  } = useContext(FixturesTableContext);

  const createFilterChip = (
    columnName: string,
    value: AnyPrimitive,
    onDelete?: VoidFunction,
  ) => {
    return (
      <Chip
        key={columnName + value}
        sx={{
          cursor: 'inherit',
        }}
        size='small'
        label={`${columnName}: ${value ?? 'none'}`}
        onDelete={onDelete}
        variant='filled'
        color='default'
      />
    );
  };

  const getFiltersChips = (
    filters: FixturesFilter[],
    onDelete?: (filterIndex: number) => void,
  ) =>
    filters.map(
      ({ displayName, property, displayValue, value, exclude }, index) => {
        const onFilterDelete = onDelete ? () => onDelete(index) : undefined;
        const name: string = `${exclude ? 'Hide ' : ''}${
          displayName || property
        }`;
        const val = displayValue || value;
        return createFilterChip(name, val, onFilterDelete);
      },
    );

  return (
    <Stack divider={<Divider flexItem />} gap={0.5}>
      <Toolbar
        sx={{
          pl: { sm: 3 },
          pr: { xs: 1, sm: 3 },
        }}
      >
        <Stack
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          width='100%'
        >
          <SearchField
            searchValue={ftSearch}
            placeholder={COMMON_STRING.SEARCH}
            onValueChange={setFtSearch}
          />
          <Stack flexDirection='row' gap={1}>
            <CheckboxActionMenu
              selectedItems={selectedFixtures}
              selectHandler={handleDeselectAllFixtures}
            >
              <CheckboxAssignMeItem
                deselectHandler={handleDeselectAllFixtures}
                selectedItems={selectedFixtures}
                assignMeHandler={assignMeHandler}
              />
              <CheckboxUnassignMeItem
                deselectHandler={handleDeselectAllFixtures}
                selectedItems={selectedFixtures}
                unAssignMeHandler={unAssignMeHandler}
              />
            </CheckboxActionMenu>
            <FixturesFilterButtons disableFilters={false} size='medium' />
            <ButtonGroup variant='outlined' size='small'>
              {REFRESH_INTERVALS.map((interval) => (
                <RefreshIntervalButton
                  actualRefreshInterval={refreshInterval}
                  refreshInterval={interval}
                  setRefreshInterval={setRefreshInterval}
                  key={interval}
                />
              ))}
            </ButtonGroup>
          </Stack>
        </Stack>
      </Toolbar>
      {!!filtersList.length && (
        <Stack
          direction='row'
          gap={0.5}
          alignItems='center'
          flex={1}
          flexWrap='wrap'
          sx={{
            pl: { sm: 3 },
            pr: { xs: 1, sm: 3 },
            pb: { sm: 1 },
            pt: { sm: 1 },
          }}
        >
          {getFiltersChips(filtersList, onFilterRemove)}
        </Stack>
      )}
    </Stack>
  );
};
