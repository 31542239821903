import { PropsWithChildren, useState } from 'react';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';
import { useDebounce, useLocalStorage } from 'usehooks-ts';
import { useFixturesWithIncidents } from '@/service/hooks/useFixturesWithIncidents';
import { Collection } from '@/contexts/fixturesWithIncidents/types';
import { fixturesWithIncidentsApi } from '@/service/endpoints/fixturesWithIncidents/fixturesWithIncidents.endpoints';
import {
  FixturesTableContext,
  FixturesTableContextType,
} from '@/components/FixturesTable/context/FixturesTableContext';
import { SUCCESS_STRING } from '@/constants/dictionary';
import { useFixturesFilters } from '@/components/FixturesTable/FixturesFilters/useFixturesFilters';
import { parseSelectedFilters } from '@/components/FixturesTable/context/utils';
import { LS_KEY } from '@/contexts/preferences/constants';

export const FixturesTableProvider = ({ children }: PropsWithChildren) => {
  const { enqueueSnackbar } = useSnackbar();
  const fixturesFilters = useFixturesFilters();
  const [refreshInterval, setRefreshInterval] = useState(10000);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>(
    LS_KEY.FIXTURES_PAGINATION,
    5,
  );
  const [ftSearch, setFtSearch] = useState('');
  const [selectedFixtures, setSelectedFixtures] = useState<
    Collection['collectionId'][]
  >([]);
  const debouncedFtSearch = useDebounce(ftSearch, 500);

  const { fixturesWithIncidents, isLoading, mutate } = useFixturesWithIncidents(
    {
      refreshInterval,
      queryParams: {
        page: page + 1,
        limit: rowsPerPage,
        ...parseSelectedFilters(fixturesFilters.filtersList, debouncedFtSearch),
      },
    },
  );

  const handleSelectAllFixtures = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.checked && fixturesWithIncidents) {
      const newSelected = fixturesWithIncidents.collections.data.map(
        (n) => n.collectionId,
      );
      setSelectedFixtures(newSelected);
      return;
    }
    setSelectedFixtures([]);
  };

  const handleSelectFixture = (
    event: React.MouseEvent<unknown>,
    id: string,
  ) => {
    const selectedIndex = selectedFixtures.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedFixtures, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedFixtures.slice(1));
    } else if (selectedIndex === selectedFixtures.length - 1) {
      newSelected = newSelected.concat(selectedFixtures.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedFixtures.slice(0, selectedIndex),
        selectedFixtures.slice(selectedIndex + 1),
      );
    }
    setSelectedFixtures(newSelected);
  };

  const handleDeselectAllFixtures = () => setSelectedFixtures([]);

  const assignMeHandler = async (collectionIds: Collection['collectionId'][]) =>
    await fixturesWithIncidentsApi
      .assignToIssues({
        body: {
          collectionIds,
          assignedOn: new Date().toISOString(),
          requestId: uuid(),
        },
      })
      .then(() =>
        enqueueSnackbar(SUCCESS_STRING.SUCCESSFULLY_ASSIGNED_TO_ISSUES, {
          variant: 'success',
        }),
      )
      .catch((e) =>
        enqueueSnackbar(e.message, {
          variant: 'error',
        }),
      )
      .finally(() => mutate());

  const unAssignMeHandler = async (
    collectionIds: Collection['collectionId'][],
  ) =>
    await fixturesWithIncidentsApi
      .unAssignToIssues({
        body: {
          collectionIds,
          resolvedAt: new Date().toISOString(),
          requestId: uuid(),
        },
      })
      .then(() =>
        enqueueSnackbar(SUCCESS_STRING.SUCCESSFULLY_UNASSIGNED_FROM_ISSUES, {
          variant: 'success',
        }),
      )
      .catch((e) =>
        enqueueSnackbar(e.message, {
          variant: 'error',
        }),
      )
      .finally(() => mutate());

  const providerValue: FixturesTableContextType = {
    data: { fixturesWithIncidents, isLoading },
    refresh: {
      refreshInterval,
      setRefreshInterval,
    },
    pagination: {
      page,
      setPage,
      rowsPerPage,
      setRowsPerPage,
    },
    checkboxes: {
      handleSelectFixture,
      handleSelectAllFixtures,
      selectedFixtures,
      handleDeselectAllFixtures,
    },
    actions: { assignMeHandler, unAssignMeHandler },
    filters: { ftSearch, setFtSearch, ...fixturesFilters },
  };

  return (
    <FixturesTableContext.Provider value={providerValue}>
      {children}
    </FixturesTableContext.Provider>
  );
};
