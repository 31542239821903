import {
  FC,
  MouseEvent,
  ReactElement,
  cloneElement,
  useMemo,
  useState,
} from 'react';
import { Divider, Menu } from '@mui/material';
import {
  BaseMTMenuProps,
  MenuAnchorOrigin,
  MenuTransformOrigin,
} from './types';

export type MTMenuProps<Model = any> = {
  showWithoutItems?: boolean;
  menuButton: ReactElement;
  inactive?: boolean;
  groupTitle?: ReactElement;
  anchorOrigin?: MenuAnchorOrigin;
  onOpen?: () => void;
  onClose?: () => void;
  transformOrigin?: MenuTransformOrigin;
} & BaseMTMenuProps<Model>;

const MenuButton = ({
  onClick,
  menuButton,
}: {
  onClick: (event: MouseEvent<HTMLElement>) => void;
  menuButton: ReactElement;
}) =>
  menuButton
    ? cloneElement(menuButton, { onClick, ...menuButton.props })
    : null;

export const MTMenu: FC<MTMenuProps> = ({
  model,
  refreshData,
  children,
  showWithoutItems = false,
  menuButton,
  onOpen,
  onClose,
  inactive = false,
  groupTitle = null,
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  transformOrigin = { vertical: 'top', horizontal: 'right' },
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e?.stopPropagation();

    if (inactive) {
      return;
    }

    setAnchorEl(e.currentTarget);
    onOpen?.();
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e?.stopPropagation();

    setAnchorEl(null);
    onClose?.();
  };

  const menuItems = useMemo(() => {
    if (!children) {
      return [];
    }

    if (!Array.isArray(children)) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      children = [children];
    }

    return children
      .filter((c) => c != null)
      .map((c, index) => {
        const element = cloneElement(c, {
          key: index,
          model: model,
          refreshData,
          closeMenu: handleClose,
          ...c.props,
        });

        const menuItems = [element];

        if (c.props?.dividerPosition) {
          if (c.props.dividerPosition === 'before') {
            menuItems.unshift(
              <Divider key={`${index}-divider`} sx={{ my: 1 }} />,
            );
          } else {
            menuItems.push(<Divider key={`${index}-divider`} sx={{ my: 1 }} />);
          }
        }

        return menuItems;
      });
  }, [children, model, refreshData]);

  if (menuItems.length === 0 && !showWithoutItems) {
    return null;
  }

  return (
    <>
      <MenuButton onClick={handleClick} menuButton={menuButton} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ zIndex: ({ zIndex }) => zIndex.appBar }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {groupTitle}
        {menuItems}
      </Menu>
    </>
  );
};
