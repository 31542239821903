import { styled } from '@mui/material/styles';
import {
  indicatorSize,
  indicatorSpacing,
} from '@/components/ConnectionStatus/SimpleIndicator';
import { IssueSeverity } from '@/contexts/fixturesWithIncidents/types';

interface SuccessIndicatorProps {
  issueSeverity: IssueSeverity;
}

export const SuccessIndicator = styled('div')<SuccessIndicatorProps>(
  ({ theme, issueSeverity }) => ({
    backgroundColor: theme.palette.indicator[issueSeverity],
    width: indicatorSize(theme),
    height: indicatorSize(theme),
    margin: indicatorSpacing(theme),
    borderRadius: '100%',
    opacity: issueSeverity === IssueSeverity.None ? '50%' : '100%',
  }),
);
