import { FIXTURE_ACTION_FLAG_STATE } from '@/service/constants';

export const COMMON_STRING = {
  LOGOUT: 'Logout',
  SHORTCUTS: 'Shortcuts',
  KEYBOARD_SHORTCUTS: 'Keyboard shortcuts',
  CHECKLIST: 'Checklist',
  FIXTURE_INFO: 'Fixture Info',
  DATA_COLLECTORS: 'Data Collectors',
  SCOREBOARD: 'Scoreboard',
  NOTIFICATIONS: 'Notifications',
  USER_AVATAR_TOOLTIP: 'Settings',
  FIXTURE_ID_PLACEHOLDER: 'Fixture ID must be in UUID format',
  FILTER_BY: 'Filter by',
  ADD_FILTER: 'Add filter',
  CLEAR_FILTERS: 'Clear filters',
  UNDO_FILTER: 'Undo filter',
  FILTERS: 'Filters',
  EXPORT_XLSX: 'Export XLSX',
  EXPORT_CSV: 'Export CSV',
  COPY: 'Copy',
  SHOW: 'Show',
  ACTION_COMMENT: 'Action comment',
  COMMENT: 'Comment',
  FIXTURE_ID: 'Fixture ID',
  EDIT_ACTION: 'Edit Action',
  DELETE_ACTION: 'Delete Action',
  RESTORE_ACTION: 'Restore Action',
  PREFERENCES: 'Preferences',
  THEME: 'THEME',
  TIMEOUTS: 'Timeouts',
  FLAG: {
    [FIXTURE_ACTION_FLAG_STATE.UNCONFIRMEND]: 'Confirm flag',
    [FIXTURE_ACTION_FLAG_STATE.CONFIRMED]: 'Unconfirm flag',
  },
  DEVICE_LOCATION: 'Device Location',
  COURT_POSITION: 'Court Position',
  APPLY: 'Apply',
  REMOVE: 'Remove',
  ADD: 'Add',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  GO_TO_EXTERNAL_STATISTICS: 'Go To External Statistics',
  GO_TO_TOP: 'Go To Top',
  REFRESH: 'Refresh',
  STATS: {
    INTERNAL_HOME: 'Internal Home',
    INTERNAL_AWAY: 'Internal Away',
    EXTERNAL_HOME: 'External Home',
    EXTERNAL_AWAY: 'External Away',
  },
  SUBMIT: 'Submit',
  SELECT_ALL: 'Select All',
  NONE: 'None',
  ACTION_JSON: 'Action JSON',
  CLOSE: 'Close',
  OVERWRITE: 'Overwrite',
  UPDATE: 'Update',
  SYNC: 'Sync',
  OK: 'OK',
  NAME: 'Name',
  SURNAME: 'Surname',
  LAST_5_RATINGS: 'Last 5 ratings',
  COMPLETED_MATCHES: 'Completed matches',
  FLAG_ON_FIELD: 'Flag on field',
  NO_VAR: 'NO VAR',
  POSSIBLE_VAR_ACTIVATED: 'Possible VAR activated',
  CONFIRM: 'Confirm',
  GAME_STATE: 'Game state',
  KICKOFF: 'Kickoff',
  PAT: 'PAT',
  REPORT_GENERATED: 'Report generated',
  DOWNLOAD: 'Download',
  OPEN_IN_A_NEW_TAB: 'Open in a new tab',
  TOTALS: 'Totals',
  COACH: 'Team/Coach',
  ALL_QUARTERS: 'All quarters',
  DID_NOT_PARTICIPATED: 'DNP',
  LIVE_STREAM: 'Live Stream',
  PICTURE_IN_PICTURE_MODE_IS_OPEN: 'Picture-in-Picture mode is open',
  OPEN_PICTURE_IN_PICTURE_MODE: 'Open in Picture-in-Picture mode',
  YES: 'Yes',
  NO: 'No',
  CALL_TO: 'Call to',
  ADD_RATING: 'ADD RATING',
  EXTERNAL_STATS: 'External Stats',
  DATA_COLLECTORS_EVALUATION: 'Data Collectors Evaluation',
  SLA_BREACH_FIXTURE_EVALUATION: 'SLA Breach Fixture Evaluation',
  ACCESS_DENIED_TEXT:
    'You do not have access to this page. Please contact administrator for more information.',
  ARE_YOU_SURE_YOU_WANT_TO_OPEN_MARKET: (open: boolean) =>
    `Are you sure you want to ${open ? 'close' : 'open'} the market?`,
  CONFIRM_CLOSE_MARKET: (open: boolean) =>
    `Confirm ${open ? 'close' : 'open'} market`,
  OPENED: 'OPENED',
  CLOSED: 'CLOSED',
  MARKET: 'market',
  MARKET_OPENED: 'Market has been opened.',
  MARKET_CLOSED: 'Market has been closed.',
  VAR_UNDERWAY: 'VAR Underway',
  VAR_OVER: 'VAR Over',
  VOLUME: 'Volume',
  TIMELINE: 'Timeline',
  PLAY: 'Play',
  PAUSE: 'Pause',
  REPLAY: 'Replay',
  REPLAY_TEN_SECONDS: 'Replay 10s',
  FORWARD_TEN_SECONDS: 'Forward 10s',
  ADVANCED: 'Advanced',
  BASKETBALL: 'Basketball',
  SCORESHEET: 'Scoresheet',
  AMERICAN_FOOTBAL: 'American Football',
  EXTERNAL: 'External',
  MATCH_STARTING_IN: 'Match starting in:',
  MATCH_RESUMING_IN: 'Match resuming in:',
  MARKET_IS_CLOSED: 'Market is closed!',
  PLAY_STOPPED: 'Play stopped',
  PLAY_RESUMED: 'PLAY RESUMED',
  ALL: 'All',
  ONLY_NOT_UPDATED: 'Only not updated',
  SOON: 'SOON',
  NOT_AVAILABLE: 'N/A',
  ACTION: 'Action',
  SEARCH_ACTIONS: 'Search Actions',
  ACTION_TYPES: 'Action Types',
  NEW_TEMPLATE: 'New Template',
  FILTER_TEMPLATE_ADD_TOOLTIP:
    'Apply selected filters to currently active template',
  RESET_TEMPLATE_TOOLTIP: 'Reset current template',
  PROCEED: 'Proceed',
  DONT_SHOW_AGAIN: 'Do not show again',
  ADD_TEMPLATE: '+ Add template',
  OVERRIDE_TEMPLATE: 'Override template',
  OVERRIDE_TEMPLATE_BODY:
    'You are about to override selected template with currently active filters. Are you sure you want to proceed?',
  CHANGING_TEMPLATE: 'Changing template',
  CHANGING_TEMPLATE_BODY:
    'You are about to change the filter template and all currently applied temporary filters will be lost. Are you sure you want to proceed?',
  TEMPLATE_DELETE_CONFIRM:
    'Are you sure you want to delete this filter template?',
  NO_TEMPLATE: 'No Template',
  BASIC_TABLE: 'Basic Table',
  MAC: 'Mac',
  WINDOWS: 'Windows',
  LINUX: 'Linux',
  UNKNOWN: 'Unknown',
  CTRL_KEY: 'CTRL',
} as const;

export enum SPORT_ID {
  SOCCER = 1,
  BASKETBALL = 2,
  SNOOKER = 3,
  AMERICAN_FOOTBALL = 6,
  ICE_HOCKEY = 8,
}

export const VISIBILITY = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
} as const;

export const CHECKBOX_STATE = {
  CHECKED: 'checked',
  UNCHECKED: 'unchecked',
} as const;

export const HOT_KEY = {
  FIXTURE_INFO: 'i',
  DATA_COLLECTORS: 'd',
  PREFERENCES: 'p',
  THEME: 't',
  SCORE_BOARD: 's',
};

export const RANK = {
  BRONZE: 'Bronze',
  SILVER: 'Silver',
  GOLD: 'Gold',
  PLATINUM: 'Platinum',
};

export const CONTENT_TAB = {
  MATCH_DATA: 'Match Data',
  LINEUPS: 'Lineups',
  STATISTICS: 'Statistics',
  STREAMING: 'Streaming',
  QUALITY_ASSURANCE: 'Quality Assurance',
} as const;

export const COLLECTION_STATUS_ID = {
  SCHEDULED: 0,
  LIVE: 1,
  POST_MATCH: 2,
  SUSPENDED: 3,
  DROPPED: 4,
  COMPLETED: 5,
  POST_MATCH_CHECKED: 6,
} as const;
export type CollectionStatusId =
  (typeof COLLECTION_STATUS_ID)[keyof typeof COLLECTION_STATUS_ID];

export const COLLECTION_STATUS = {
  [COLLECTION_STATUS_ID.SCHEDULED]: 'Scheduled',
  [COLLECTION_STATUS_ID.LIVE]: 'Live',
  [COLLECTION_STATUS_ID.POST_MATCH]: 'Post Match',
  [COLLECTION_STATUS_ID.SUSPENDED]: 'Suspended',
  [COLLECTION_STATUS_ID.DROPPED]: 'Dropped',
  [COLLECTION_STATUS_ID.COMPLETED]: 'Completed',
  [COLLECTION_STATUS_ID.POST_MATCH_CHECKED]: 'Post Match Checked',
} as const;

export type CollectionStatus =
  (typeof COLLECTION_STATUS)[keyof typeof COLLECTION_STATUS];
