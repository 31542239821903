import useSWR from 'swr';
import {
  SERVICE_ENDPOINT,
  fetchFromMTService,
} from '../fetcher/monitoringToolService';
import { HTTP_STATUS_CODE } from '../constants';
import { ScorerInfoConfig } from '../types';
import { ApiError } from '../ApiError';
import { UseFixtureConfigParams } from './useFixtureConfig';

export const useScorerInfoConfig = ({
  token,
  fixtureId,
}: UseFixtureConfigParams) => {
  const { data, error } = useSWR<ScorerInfoConfig[], ApiError>(
    token && fixtureId
      ? {
          token,
          endpoint:
            SERVICE_ENDPOINT.Fixture(fixtureId).fixtureCollectorsData.GET,
        }
      : null,
    fetchFromMTService,
    {
      onErrorRetry: (e, _, config, revalidate, { retryCount }) => {
        if (e.status === HTTP_STATUS_CODE.NOT_FOUND) return;
        if (e.status === HTTP_STATUS_CODE.UNAUTHORIZED) return;
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
      revalidateOnFocus: false,
    },
  );

  return {
    data,
    error,
    isLoading: !data && !error,
  };
};
