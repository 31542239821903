import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { AccessToken, IDToken, Tokens, UserClaims } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffectOnce } from 'usehooks-ts';
import { ExtendUserSession } from '@/components/ExtendUserSession/ExtendUserSession';
import { UserContext } from './context';

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userInfo, setUserInfo] = useState<UserClaims>();
  const [tokens, setTokens] = useState<Tokens | undefined>();

  useEffectOnce(() => {
    oktaAuth.tokenManager.getTokens().then((response) => {
      if (
        response.accessToken !== tokens?.accessToken ||
        response.idToken !== tokens?.idToken ||
        response.refreshToken !== tokens?.refreshToken
      ) {
        setTokens(response);
      }
    });
  });

  useEffect(() => {
    if (!tokens || !tokens.accessToken || !tokens.idToken) {
      return;
    }
    setIsLoading(true);

    getUserInfo(tokens.accessToken, tokens.idToken)
      .then(setUserInfo)
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens?.accessToken, tokens?.idToken]);

  const login = () => oktaAuth.signInWithRedirect();
  const logout = () => {
    oktaAuth.closeSession().then(() => {
      oktaAuth.signInWithRedirect();
    });
  };

  const getUserInfo = (accessToken: AccessToken, idToken: IDToken) => {
    return oktaAuth.token.getUserInfo(accessToken, idToken);
  };

  return (
    <UserContext.Provider
      value={{
        user: userInfo,
        isLoading,
        isAuthenticated: !!authState?.isAuthenticated,
        login,
        logout,
      }}
    >
      <ExtendUserSession />
      {children}
    </UserContext.Provider>
  );
};
