import { Divider, Stack } from '@mui/material';
import { FC } from 'react';
import { ScorerInfoConfig } from '@/service/types';
import { COMMON_STRING } from '@/constants/dictionary';
import { PartialBy } from '@/types/common';
import { LabelWithData } from '../common/LabelWithData';

export const ScorerInfo: FC<PartialBy<ScorerInfoConfig, 'userId'>> = ({
  firstName,
  lastName,
  averageFor5LastFixtures,
  numberOfCompletedMatches,
  lastDataCollectionDate,
}) => {
  return (
    <Stack
      direction='row'
      gap={2}
      px={2}
      py={1}
      minWidth='max-content'
      justifyContent={'space-around'}
      flex='1 0 0'
      border={(theme) => `1px solid ${theme.palette.divider}`}
      divider={<Divider orientation='vertical' flexItem />}
    >
      <LabelWithData label={COMMON_STRING.NAME} data={firstName} />
      <LabelWithData label={COMMON_STRING.SURNAME} data={lastName} />
      <LabelWithData
        label={COMMON_STRING.LAST_5_RATINGS}
        data={averageFor5LastFixtures ?? 0}
      />
      <LabelWithData
        label={COMMON_STRING.LAST_MATCH_DATE}
        data={lastDataCollectionDate}
      />
      <LabelWithData
        label={COMMON_STRING.COMPLETED_MATCHES}
        data={numberOfCompletedMatches ?? 0}
      />
    </Stack>
  );
};
