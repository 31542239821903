import { Divider, Drawer } from '@mui/material';
import { useContext } from 'react';
import { UIStateContext } from '@/contexts/UIState/context';
import { PANEL } from '@/contexts/UIState/types';
import { FixturesFiltersForm } from '@/components/FixturesTable/FixturesFilters/FixturesFiltersForm';
import { DialogTitleWithClose } from '@/components/common/DialogTitleWithClose';
import { COMMON_STRING } from '@e2e/constants/common';
import {
  FixturesTableContext,
  FixturesTableContextType,
} from '@/components/FixturesTable/context/FixturesTableContext';

const DRAWER_WIDTH = '650px';

export const FixturesFiltersDrawer = () => {
  const { openedPanel, setOpenedPanel } = useContext(UIStateContext);

  const {
    filters: { filtersList, setFilters },
  } = useContext<FixturesTableContextType>(FixturesTableContext);

  const onClose = () => setOpenedPanel(null);

  return (
    <Drawer
      anchor='right'
      open={openedPanel === PANEL.FILTERS}
      onClose={onClose}
      keepMounted
    >
      <DialogTitleWithClose close={onClose}>
        {COMMON_STRING.FILTERS}
      </DialogTitleWithClose>
      <Divider />
      <FixturesFiltersForm
        filters={filtersList}
        onApply={(newFilters) => {
          setFilters(newFilters);
          onClose();
        }}
        onCancel={onClose}
        drawerWidth={DRAWER_WIDTH}
      />
    </Drawer>
  );
};
