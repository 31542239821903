import { PropsWithChildren } from 'react';
import { SxProps, TableCell, TableCellProps } from '@mui/material';

export interface FixturesTableCellProps extends PropsWithChildren {
  isRowOpened: boolean;
  sxProps?: SxProps;
  props?: TableCellProps;
}

export const FixturesTableCell = ({
  isRowOpened,
  children,
  sxProps,
  props,
}: FixturesTableCellProps) => {
  return (
    <TableCell
      {...props}
      sx={{
        borderBottom: isRowOpened
          ? (theme) => `1px dashed ${theme.palette.divider}`
          : 'none',
        ...sxProps,
      }}
    >
      {children}
    </TableCell>
  );
};
