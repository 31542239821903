import { AnyPrimitive } from '@/types/common';
import { FixturesFilter } from '@/components/FixturesTable/FixturesFilters/types';
import { FixturesFilterProperty } from '@/components/FixturesTable/FixturesFilters/constants';

export const isInFixturesFilters = (
  filters: FixturesFilter[],
  property: FixturesFilterProperty,
  value: AnyPrimitive,
  checkFn?: (filter: FixturesFilter) => boolean,
) => {
  return filters.some((filter) => {
    const checkFnResult = checkFn ? checkFn(filter) : true;
    return (
      filter.property === property && filter.value === value && checkFnResult
    );
  });
};
