import { Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { COMMON_STRING } from '@/constants/dictionary';

interface InformationRowProps extends PropsWithChildren {
  label: string;
  description?: string;
}

export const InformationRow = ({
  label,
  description,
  children,
}: InformationRowProps) => {
  return (
    <Stack flexDirection='row' gap={0.5} alignItems='start'>
      <Typography variant='body2' fontWeight={500} textTransform='capitalize'>
        {label}:
      </Typography>
      {children ? (
        children
      ) : description ? (
        <Typography variant='body2'>{description}</Typography>
      ) : (
        <Typography variant='body2'>{COMMON_STRING.NOT_AVAILABLE}</Typography>
      )}
    </Stack>
  );
};
