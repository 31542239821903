import {
  Box,
  Divider,
  Paper,
  Popover,
  Table,
  TableContainer,
  TablePagination,
} from '@mui/material';
import React, {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ClearIcon from '@mui/icons-material/Clear';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { FixturesTableHead } from '@/components/FixturesTable/FixturesTableHead';
import { FixturesTableToolbar } from '@/components/FixturesTable/FixturesTableToolbar';
import { FixturesTableBody } from '@/components/FixturesTable/FixturesTableBody';
import { findRowInPath } from '@/components/ActionsTable/utils';
import { BUTTON_NAME } from '@/components/ActionsTable/constants';
import { DICTIONARY } from '@/constants/dictionary';
import { getRowCollectionId } from '@/components/FixturesTable/utils';
import {
  ContextMenu,
  ContextMenuItem,
} from '@/components/ActionsTable/ContextMenu';
import { Collection, Resolver } from '@/contexts/fixturesWithIncidents/types';
import { PermissionsChecker } from '@/components/PermissionsChecker/PermissionsChecker';
import { FixturesTableContext } from '@/components/FixturesTable/context/FixturesTableContext';
import { KeyboardButton } from '@/components/common/KeyboardButton';
import { UIStateContext } from '@/contexts/UIState/context';
import { FixturesFiltersDrawer } from '@/components/FixturesTable/FixturesFilters/FixturesFiltersDrawer';

const ROWS_PER_PAGE_OPTIONS = [5, 10, 25];
const CONTAINER_HEIGHT_WITH_FILTERS = 284;
const CONTAINER_HEIGHT_WITHOUT_FILTERS = 234;

export const FixturesTable = () => {
  const {
    data: { fixturesWithIncidents },
    pagination: { rowsPerPage, setRowsPerPage, setPage, page },
    actions: { assignMeHandler, unAssignMeHandler },
    filters: { ftSearch, filtersList },
  } = useContext(FixturesTableContext);

  const {
    assignCollection,
    unAssignCollection,
    goToCollection,
    setAssignCollection,
    setUnAssignCollection,
    setGoToCollection,
  } = useContext(UIStateContext);

  const [contextMenuPosition, setContextMenuPosition] = useState({
    top: 0,
    left: 0,
  });
  const [contextMenuItems, setContextMenuItems] = useState<ContextMenuItem[]>(
    [],
  );
  const [hoverCollection, setHoverCollection] = useState<
    Collection | undefined
  >(undefined);

  const findCollectionById = useCallback(
    (collectionId: Collection['collectionId']) =>
      fixturesWithIncidents?.collections.data.find(
        (collection) => collection.collectionId === collectionId,
      ),
    [fixturesWithIncidents],
  );

  useEffect(() => {
    handleKeyboardPressForAssignCollection();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignCollection]);

  useEffect(() => {
    handleKeyboardPressForUnAssignCollection();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unAssignCollection]);

  useEffect(() => {
    handleKeyboardPressForGoToCollection();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goToCollection]);

  useEffect(() => {
    if (page === 0) return;

    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ftSearch]);
  const handleKeyboardPressForAssignCollection = () => {
    if (
      hoverCollection &&
      !canUnAssignResolver(hoverCollection.resolver) &&
      assignCollection
    ) {
      assignMeHandler([hoverCollection.collectionId]);
      setAssignCollection(false);
    } else {
      setAssignCollection(false);
    }
  };

  const handleKeyboardPressForUnAssignCollection = () => {
    if (
      hoverCollection &&
      canUnAssignResolver(hoverCollection.resolver) &&
      unAssignCollection
    ) {
      unAssignMeHandler([hoverCollection.collectionId]);
      setUnAssignCollection(false);
    } else {
      setUnAssignCollection(false);
    }
  };

  const handleKeyboardPressForGoToCollection = () => {
    if (hoverCollection && goToCollection) {
      window.open(`/${hoverCollection.fixtureId}`);
      setGoToCollection(false);
    } else setGoToCollection(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const closeContextMenu = () => {
    setContextMenuItems([]);
  };

  const canUnAssignResolver = (resolver: Resolver | null) =>
    resolver?.resolvedOn === null;

  const openContextMenu = ({
    event,
    clickedRowCollectionId,
    extraMenuItems = [],
  }: {
    event: MouseEvent<HTMLElement>;
    clickedRowCollectionId: Collection['collectionId'];
    extraMenuItems?: ContextMenuItem[];
  }) => {
    const collection = findCollectionById(clickedRowCollectionId);
    if (!collection) return;

    const { resolver } = collection;

    const newContextMenuItems = [...extraMenuItems];

    const isAssigned = canUnAssignResolver(resolver);

    if (!isAssigned) {
      newContextMenuItems.push({
        Icon: AssignmentTurnedInIcon,
        label: (
          <>
            {DICTIONARY.COMMON.ASSIGN_ME} <KeyboardButton theKey='a' />
          </>
        ),
        onClick: () => {
          assignMeHandler([clickedRowCollectionId]);
          closeContextMenu();
        },
      });
    }

    if (isAssigned) {
      newContextMenuItems.push({
        Icon: ClearIcon,
        label: (
          <>
            {DICTIONARY.COMMON.UNASSIGN_ME} <KeyboardButton theKey='u' />
          </>
        ),
        onClick: () => {
          unAssignMeHandler([clickedRowCollectionId]);
          closeContextMenu();
        },
      });
    }

    newContextMenuItems.push({
      Icon: OpenInNew,
      label: (
        <>
          {DICTIONARY.COMMON.GO_TO_COLLECTION} <KeyboardButton theKey='g' />
        </>
      ),
      onClick: () => {
        window.open(`/${collection.fixtureId}`);
        closeContextMenu();
      },
    });

    setContextMenuPosition({ top: event.pageY + 5, left: event.pageX });
    setContextMenuItems(newContextMenuItems);
  };

  const onTableClick = (event: MouseEvent<HTMLTableSectionElement>): void => {
    const path = event.nativeEvent.composedPath() as HTMLElement[];

    const button = path.find(({ tagName }) => tagName === 'BUTTON') as
      | HTMLButtonElement
      | undefined;

    if (!button) return;

    const clickedRowElement = findRowInPath(path);
    if (!clickedRowElement) return;

    const clickedRowCollectionId = getRowCollectionId(clickedRowElement);
    if (!clickedRowCollectionId) return;

    if (button.name === BUTTON_NAME.MORE) {
      openContextMenu({ event, clickedRowCollectionId });
      return;
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          mb: 2,
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
        elevation={0}
      >
        <FixturesTableToolbar />
        <Divider flexItem variant='fullWidth' />
        <TableContainer
          sx={{
            height: `calc(100vh - ${
              !!filtersList.length
                ? CONTAINER_HEIGHT_WITH_FILTERS
                : CONTAINER_HEIGHT_WITHOUT_FILTERS
            }px)`,
          }}
        >
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby='tableTitle'
            size='small'
            stickyHeader
          >
            <FixturesTableHead />
            <FixturesTableBody
              onTableClick={onTableClick}
              setHoverCollection={setHoverCollection}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component='div'
          count={fixturesWithIncidents?.collections.pagination.results || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ borderTop: (theme) => `1px solid ${theme.palette.divider}` }}
        />
        <Popover
          open={!!contextMenuItems.length}
          onClose={closeContextMenu}
          anchorReference='anchorPosition'
          anchorPosition={contextMenuPosition}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          transitionDuration={0}
        >
          <PermissionsChecker name='FixturePage.MatchData.KebabMenu'>
            <ContextMenu items={contextMenuItems}></ContextMenu>
          </PermissionsChecker>
        </Popover>
      </Paper>
      <FixturesFiltersDrawer />
    </Box>
  );
};
