import {
  AssignToIssues,
  UnAssignFromIssues,
} from '@/service/endpoints/fixturesWithIncidents/fixturesWithIncidents.types';
import { SERVICE_ENDPOINT } from '@/service/fetcher/monitoringToolService';
import { axios } from '../../request';

const assignToIssues: AssignToIssues = ({ body }) =>
  axios.post(
    SERVICE_ENDPOINT.FixturesWithIncidents().AssignToIssues.POST,
    body,
  );

const unAssignToIssues: UnAssignFromIssues = ({ body }) =>
  axios.post(
    SERVICE_ENDPOINT.FixturesWithIncidents().UnAssignFromIssues.POST,
    body,
  );

export const fixturesWithIncidentsApi = {
  unAssignToIssues,
  assignToIssues,
};
