import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import {
  EVENT_ADDED,
  EVENT_EXPIRED,
  EVENT_RENEWED,
  TokenManagerEventHandler,
  toRelativeUrl,
} from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffectOnce } from 'usehooks-ts';
import { useSnackbar } from 'notistack';
import { UserContext } from '@/contexts/user/context';
import { MTDialog } from '@/components/common/MTDialog';
import { COMMON_STRING } from '@/constants/dictionary';
import { DEFAULT_EXPIRED_SESSION_SNACKBAR_OPTIONS } from '@/contexts/visualNotifications/constants';
import { SnackbarSessionExpiredAction } from '@/components/SnackbarActions/SnackbarSessionExpiredAction';
import { useSessionTimer } from '@/utils/hooks/useSessionTimer';

export const ExtendUserSession = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const { logout } = useContext(UserContext);
  const { oktaAuth } = useOktaAuth();
  const tokens = oktaAuth.tokenManager.getTokensSync();
  const [accessTokenExpireAt, setAccessTokenExpireAt] = useState<
    number | undefined
  >(
    tokens.accessToken &&
      oktaAuth.tokenManager.getExpireTime(tokens.accessToken) * 1000,
  );
  const { showTimer, timerDuration, setShowTimer } = useSessionTimer({
    accessTokenExpireAt: accessTokenExpireAt || 0,
  });
  const [isExpiredDialogOpen, setIsExpiredDialogOpen] = useState(false);

  useEffect(() => {
    if (showTimer && !isExpiredDialogOpen) {
      enqueueSnackbar({
        ...DEFAULT_EXPIRED_SESSION_SNACKBAR_OPTIONS,
        key: 'sessionExpired',
        style: {
          backgroundColor:
            theme.palette.snackbarColors.sessionExpired.backgroundColor,
          color: theme.palette.getContrastText(
            theme.palette.snackbarColors.sessionExpired.backgroundColor,
          ),
          minWidth: '7rem',
        },
        action: () => (
          <SnackbarSessionExpiredAction
            timerDuration={timerDuration as number}
            extendAccessToken={extendAccessToken}
            setShowTimer={setShowTimer}
            setIsExpiredDialogOpen={setIsExpiredDialogOpen}
          />
        ),
      });
    } else {
      closeSnackbar('sessionExpired');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTimer]);

  useEffectOnce(() => {
    const expired: TokenManagerEventHandler = (key) => {
      if (key === 'accessToken') {
        const originalUri = toRelativeUrl(
          window.location.href,
          window.location.origin,
        );
        oktaAuth.setOriginalUri(originalUri);
      }
    };

    const renewed: TokenManagerEventHandler = (key, token) => {
      if (key === 'accessToken') {
        if (accessTokenExpireAt !== token.expiresAt * 1000) {
          setAccessTokenExpireAt(token.expiresAt * 1000);
        }
      }
    };

    const added: TokenManagerEventHandler = (key, token) => {
      if (key === 'accessToken') {
        if (accessTokenExpireAt !== token.expiresAt * 1000) {
          setAccessTokenExpireAt(token.expiresAt * 1000);
        }
      }
    };

    oktaAuth.tokenManager.on(EVENT_EXPIRED, expired);
    oktaAuth.tokenManager.on(EVENT_RENEWED, renewed);
    oktaAuth.tokenManager.on(EVENT_ADDED, added);

    return () => {
      oktaAuth.tokenManager.off(EVENT_EXPIRED, expired);
      oktaAuth.tokenManager.off(EVENT_RENEWED, renewed);
      oktaAuth.tokenManager.off(EVENT_ADDED, added);
    };
  });

  const extendAccessToken = async () => {
    await oktaAuth.tokenManager.renew('accessToken');
    await setShowTimer(false);
  };

  const logoutUser = () => {
    oktaAuth.setOriginalUri(
      toRelativeUrl(window.location.href, window.location.origin),
    );
    logout();
  };

  return (
    <MTDialog
      open={isExpiredDialogOpen}
      title={COMMON_STRING.SESSION_EXPIRED}
      fullWidth={false}
      onClose={logoutUser}
    >
      {COMMON_STRING.YOUR_SESSION_HAS_EXPIRED}
    </MTDialog>
  );
};
