import { FC } from 'react';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { CheckboxMenuItem } from '@/components/FixturesTable/CheckboxActionMenu/CheckboxMenuItems/CheckboxMenuItem';
import { COMMON_STRING } from '@/constants/dictionary';
import { Collection } from '@/contexts/fixturesWithIncidents/types';

interface CheckboxAssignMeItemProps {
  deselectHandler: VoidFunction;
  selectedItems: Collection['collectionId'][];
  assignMeHandler: (collectionIds: Collection['collectionId'][]) => void;
}

export const CheckboxAssignMeItem: FC<CheckboxAssignMeItemProps> = ({
  deselectHandler,
  selectedItems,
  assignMeHandler,
}) => {
  const onClickHandler = () => {
    assignMeHandler(selectedItems);
    deselectHandler();
  };

  return (
    <CheckboxMenuItem
      text={COMMON_STRING.ASSIGN_ME}
      icon={<AssignmentTurnedInIcon />}
      onClick={onClickHandler}
    />
  );
};
