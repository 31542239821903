import { PaginatedResultType } from '@/types/common';
import { MATCH_ACTION_SEND_TYPE_NAME } from '@/constants';
import { ScoringState } from '@/contexts/scoring/types';
import {
  COLLECTION_RANK_IDS,
  CollectionStatusId,
  CoverageLevelId,
  FixtureActionFlagState,
  FixtureStatusId,
  FixtureTypeId,
  SCORE_TYPE,
  ScoringAppStatus,
  USER_ROLE_ID,
  USER_ROLE_NAME,
} from '../constants';

export type FixtureConnectionStatus = {
  fixtureId: string;
  isConnected: boolean;
};

export interface SocketRequestConfirmation {
  isSuccess: boolean;
  errorMessage: string;
  requestId: string;
}

export enum MatchActionSendType {
  PENDING = 1,
  UPDATED = 2,
  CANCELLED = 3,
  DELETED = 4,
  CONFIRMED = 5,
}

export type UserRolePaginated = PaginatedResultType<UserRoles>;

export type UserRoles = Array<{
  id: USER_ROLE_ID;
  name: USER_ROLE_NAME;
}>;

export type UserRolePermission = {
  roleName: USER_ROLE_NAME;
  permissions: UserPermissions;
  userId: string;
};

export type UserPermissions = UserPermission[];

export type UserPermission = {
  name: string;
  visible: boolean;
  canCreate?: boolean;
  canRead?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
};

export interface StatusReason {
  code: number;
  reason: string;
}

export interface ReasonCodeDescription {
  code: number;
  description: string;
}

interface StatusReasonCode {
  status: number;
  reasonCodes: ReasonCodeDescription['code'][];
}

export interface ReasonOptions {
  collectionStatusReasonCodes: StatusReasonCode[];
  fixtureStatusReasonCodes: StatusReasonCode[];
  coverageLevelReasonCodes: ReasonCodeDescription['code'][];
  reasonCodeDescriptions: ReasonCodeDescription[];
}

export interface CollectionRank {
  value: COLLECTION_RANK_IDS;
  displayValue: string;
}

export interface FixtureSummary {
  fixtureId: string;
  rank?: CollectionRank | null;
  currentStatus: ScoringAppStatus;
  fixtureStatus: FixtureStatusId | number;
  fixtureStatusReason: null | StatusReason['reason'];
  fixtureStatusReasonCode: null | StatusReason['code'];
  collectionStatus: CollectionStatusId | number;
  collectionStatusReason: null | StatusReason['reason'];
  collectionStatusReasonCode: null | StatusReason['code'];
  coverageLevel: CoverageLevelId | number;
  coverageLevelReason: null | StatusReason['reason'];
  coverageLevelReasonCode: null | StatusReason['code'];
  currentPeriod: number;
  currentClock: string;
  isClockPaused: boolean;
  currentScore: MatchScore;
  periods: Period[];
  homeTeam: MatchSummaryTeam;
  awayTeam: MatchSummaryTeam;
  teamActionAggregates: TeamActionAggregates[];
  advancedSportStatistics: AdvancedStatistics | null;
  collectionId: string;
}

export interface YardLine {
  currentYardLineZone: number;
  yard: number;
}

export type TeamTimeoutsType = number;

export interface AmericanFootballFixtureState {
  awayTeamTimeoutsLeft: TeamTimeoutsType;
  homeTeamTimeoutsLeft: TeamTimeoutsType;
  currentTeamInPlay: MatchSummaryTeam['id'];
  downNumber: number;
  gameState: number;
  isGoalZoneReached: boolean;
  kickoffYard: number | null;
  playNumber: number;
  totalTimeoutsPerTeam: TeamTimeoutsType;
  yardsLeft: number;
  yardLine: YardLine;
  firstDownLine: YardLine;
}

export interface AdvancedStatistics {
  americanFootballFixtureState?: AmericanFootballFixtureState;
  gamePeriodStatistics?: GamePeriodStatistics[];
  gameStatistics?: GameStatistics;
  teamsStatistics: TeamStatistics[];
}

export interface GameStatistics {
  leadChanges: number;
  gameResultTracker: GameResultTracker[];
  timesTied: number;
  winnerId: string;
}

export interface GamePeriodStatistics {
  period: number;
  leadChanges: number;
  timesTied: number;
}

export interface GameResultTracker {
  awayTeamScore: number;
  gameClock: string;
  homeTeamScore: number;
  scoreDifference: number;
  leadingTeamId: string;
  period: number;
}

export interface TeamStatistics {
  gameStatistics: TeamGameStatistics | AmericanFootballTeamBoxScore;
  periodStatistics?: TeamPeriodStatistics[];
  periodsStatistics?: AmericanFootballTeamPeriodStatistics[];
  playersStatistics: PlayerStatistics[];
  teamId: string;
}

export interface TeamPeriodStatistics extends TeamBoxScore {
  period: number;
}

export interface ZoneStatistics {
  teamZonePointsWhenOnCourt: number;
  teamZoneShotAttemptsWhenOnCourt: number;
  teamZoneShotsMadeWhenOnCourt: number;
  zonePoints: number;
  zonePointsRatio: number;
  zonePointsRatioWhenOnCourt: number;
  zoneShotAttempts: number;
  zoneShotAttemptsRatio: number;
  zoneShotAttemptsRatioWhenOnCourt: number;
  zoneShotsMade: number;
  zoneShotsMadeRatio: number;
  zoneShotsMadeRatioWhenOnCourt: number;
  zoneShotsPercentage: number;
}

export interface TeamGameStatistics extends TeamBoxScore {
  biggestLead: number;
  biggestLeadScore: string;
  currentScoringRun: number;
  longestScoringRun: number;
  longestScoringRunFromZero: number;
  longestScoringRunScore: string;
  longestScoringRunScoreFromZero: string;
  mostPointsInPeriod: number;
  periodWithMostPoints: number;
  coachEjected: boolean;
  timeOnLead: string;
  leadChanges: number;
  timesTied: number;
  leftCornerStats: ZoneStatistics;
  rightCornerStats: ZoneStatistics;
  paintStats: ZoneStatistics;
  restrictedAreaStats: ZoneStatistics;
  midRangeStats: ZoneStatistics;
  aboveTheBreak3Stats: ZoneStatistics;
  backcourtStats: ZoneStatistics;
  ballPossessionPercentage?: number | null;
  ballPossessionTime?: string;
}

export interface AmericanFootballTeamPeriodStatistics
  extends AmericanFootballTeamBoxScore {
  period: number;
}

export interface AmericanFootballTeamBoxScore {
  rushingYards: number;
  rushingAttempts: number;
  rushingAverage: number;
  passingYards: number;
  passingAttempts: number;
  passingAverage: number;
  touchdowns: number;
  fieldGoals: number;
  yardsTotal: number;
  timeOfPossession: string;
  timeOfPossessionString: string;
  firstDowns: number;
  thirdDownConversions: number;
  thirdDownAttempts: number;
  thirdDownEfficiency: string;
  fourthDownConversions: number;
  fourthDownAttempts: number;
  fourthDownEfficiency: string;
  turnovers: number;
  fumblesLost: number;
  interceptions: number;
  sacks: number;
  penaltiesNumber: number;
  penaltiesYards: number;
}

export interface TeamBoxScore {
  assists: number;
  assistsToTurnovers: string;
  assistsToTurnoversRatio: number;
  benchPoints: number;
  benchTechnicalFouls: number;
  blocks: number;
  blocksAgainst: number;
  coachDisqualifyingFouls: number;
  coachTechnicalFouls: number;
  defensiveRebounds: number;
  defensiveTeamRebounds: number;
  disqualifyingFouls: number;
  fieldGoalAttempts: number;
  fieldGoalsMade: number;
  fieldGoalsPercentage: number;
  effectiveFieldGoalsPercentage: number;
  foulsAgainst: number;
  freeThrowAttempts: number;
  freeThrowsMade: number;
  freeThrowsPercentage: number;
  offensiveFouls: number;
  offensiveRebounds: number;
  offensiveTeamRebounds: number;
  personalFouls: number;
  pointsOffTurnovers: number;
  secondChancePoints: number;
  steals: number;
  teamLimitFouls: number;
  teamRebounds: number;
  technicalFouls: number;
  threePointerAttempts: number;
  threePointersMade: number;
  threePointersPercentage: number;
  totalPoints: number;
  totalRebounds: number;
  totalReboundsWithTeamRebounds: number;
  turnovers: number;
  twoPointerAttempts: number;
  twoPointersMade: number;
  twoPointersPercentage: number;
  unsportsmanlikeFouls: number;
  pointsInThePaint?: number;
  biggestScoringRun?: number;
  timeWithLead?: number;
}

export interface PlayerStatistics {
  playerId: string;
  gameStatistics: PlayerGameStatistics | AmericanFootballPlayerBoxScore;
  periodsStatistics: (
    | PlayerPeriodStatistics
    | AmericanFootballPlayerPeriodStatistics
  )[];
}

export interface PlayerGameStatistics extends PlayerBoxScore {
  assistsRatio: number;
  assistsRatioWhenOnCourt: number;
  blocksAgainstRatio: number;
  blocksAgainstRatioWhenOnCourt: number;
  blocksRatio: number;
  blocksRatioWhenOnCourt: number;
  defensiveReboundsRatio: number;
  defensiveReboundsRatioWhenOnCourt: number;
  ejected: boolean;
  fieldGoalsAttemptsRatio: number;
  fieldGoalsAttemptsRatioWhenOnCourt: number;
  fieldGoalsMadeRatio: number;
  fieldGoalsMadeRatioWhenOnCourt: number;
  fouledOut: boolean;
  freeThrowsAttemptsRatio: number;
  freeThrowsAttemptsRatioWhenOnCourt: number;
  freeThrowsMadeRatio: number;
  freeThrowsMadeRatioWhenOnCourt: number;
  hasDoubleDouble: boolean;
  hasQuadrupleDouble: boolean;
  hasTripleDouble: boolean;
  offensiveReboundsRatio: number;
  offensiveReboundsRatioWhenOnCourt: number;
  pointsOffTurnoversRatio: number;
  pointsOffTurnoversRatioWhenOnCourt: number;
  secondChancePointsRatio: number;
  secondChancePointsRatioWhenOnCourt: number;
  stealsRatio: number;
  stealsRatioWhenOnCourt: number;
  teamAssistsWhenOnCourt: number;
  teamBlocksAgainstWhenOnCourt: number;
  teamBlocksWhenOnCourt: number;
  teamDefensiveReboundsWhenOnCourt: number;
  teamFieldGoalAttemptsWhenOnCourt: number;
  teamFieldGoalsMadeWhenOnCourt: number;
  teamFreeThrowAttemptsWhenOnCourt: number;
  teamFreeThrowsMadeWhenOnCourt: number;
  teamOffensiveReboundsWhenOnCourt: number;
  teamPointsOffTurnoversWhenOnCourt: number;
  teamSecondChancePointsWhenOnCourt: number;
  teamStealsWhenOnCourt: number;
  teamThreePointerAttemptsWhenOnCourt: number;
  teamThreePointersMadeWhenOnCourt: number;
  teamTotalPointsWhenOnCourt: number;
  teamTotalReboundsWhenOnCourt: number;
  teamTurnoversWhenOnCourt: number;
  teamTwoPointerAttemptsWhenOnCourt: number;
  teamTwoPointersMadeWhenOnCourt: number;
  threePointersAttemptsRatio: number;
  threePointersAttemptsRatioWhenOnCourt: number;
  threePointersMadeRatio: number;
  threePointersMadeRatioWhenOnCourt: number;
  totalPointsRatio: number;
  totalPointsRatioWhenOnCourt: number;
  totalReboundsRatio: number;
  totalReboundsRatioWhenOnCourt: number;
  turnoversRatio: number;
  turnoversRatioWhenOnCourt: number;
  twoPointersAttemptsRatio: number;
  twoPointersAttemptsRatioWhenOnCourt: number;
  twoPointersMadeRatio: number;
  twoPointersMadeRatioWhenOnCourt: number;
  biggestScoringRun?: number;
}

export interface PlayerPeriodStatistics extends PlayerBoxScore {
  period: number;
}

export interface AmericanFootballPlayerPeriodStatistics
  extends AmericanFootballPlayerBoxScore {
  period: number;
}

export interface AmericanFootballPlayerBoxScore {
  offenseSacksAllowed: number;
  offenseSacksYardage: number;
  offenseFumbles: number;
  offenseFumblesLost: number;
  passingAttempts: number;
  passingCompletions: number;
  passingYards: number;
  passingTouchdowns: number;
  passingInterceptions: number;
  passingLongest: number;
  passingCompletionPercentage: number;
  passingYardsPerAttempt: number;
  passingTouchdownPercentage: number;
  passingInterceptionPercentage: number;
  rushingYards: number;
  rushingAttempts: number;
  rushingTouchdowns: number;
  rushingLongest: number;
  rushingAverage: number;
  receivingReceptions: number;
  receivingYards: number;
  receivingYardsAfterCatch: number;
  receivingAverage: number;
  receivingTouchdowns: number;
  receivingDroppedPasses: number;
  receivingLongest: number;
  defenseTacklesSolo: number;
  defenseTacklesAssisted: number;
  defenseTacklesForLoss: number;
  defenseSacksSolo: number;
  defenseSacksAssisted: number;
  defenseSackYards: number;
  defensePassesDefended: number;
  defenseForcedFumbles: number;
  defenseFumbleRecoveries: number;
  defenseFumbleReturnYards: number;
  defenseHurries: number;
  defenseSafeties: number;
  defenseBlocks: number;
  defenseInterceptions: number;
  defenseInterceptionReturnYardage: number;
  defenseAverageYardsPerInterception: number;
  defenseTouchdowns: number;
  fieldGoalsMade: number;
  fieldGoalsAttempted: number;
  fieldGoalsPercentage: number;
  fieldGoalsLongest: number;
  fieldGoalsBlocked: number;
  kickoffsKickoffs: number;
  kickoffsYards: number;
  kickoffsOutOfBounds: number;
  kickoffsAverage: number;
  kickoffsTouchbacks: number;
  kickoffsTouchbackPercentage: number;
  kickoffsOnsideKicks: number;
  kickoffsOnsideKicksRecovered: number;
  punting: number;
  puntingYards: number;
  puntingAverage: number;
  puntingLongest: number;
  puntingTouchbacks: number;
  puntingTouchbackPercentage: number;
  puntingInside20: number;
  puntingPercentInside20: number;
  puntingBlocked: number;
  puntingReturns: number;
  puntingReturnYardage: number;
  puntingReturnAverage: number;
  kickPuntReturnsYards: number;
  kickPuntReturnsAttempts: number;
  kickPuntReturnsTouchdowns: number;
  kickPuntReturnsFairCatches: number;
  kickPuntReturnsLongest: number;
  kickPuntReturnsAverage: number;
}

export interface PlayerBoxScore {
  assists: number;
  assistsToTurnovers: string;
  assistsToTurnoversRatio: number;
  blocks: number;
  blocksAgainst: number;
  defensiveRebounds: number;
  disqualifyingFouls: number;
  fieldGoalAttempts: number;
  fieldGoalsMade: number;
  fieldGoalsPercentage: number;
  effectiveFieldGoalsPercentage: number;
  foulsAgainst: number;
  freeThrowAttempts: number;
  freeThrowsMade: number;
  freeThrowsPercentage: number;
  minutesPlayedString: string;
  offensiveFouls: number;
  offensiveRebounds: number;
  personalFouls: number;
  playerLimitFouls: number;
  plusMinus: number;
  pointsOffTurnovers: number;
  secondChancePoints: number;
  steals: number;
  technicalFouls: number;
  threePointerAttempts: number;
  threePointersMade: number;
  threePointersPercentage: number;
  totalPoints: number;
  totalRebounds: number;
  turnovers: number;
  twoPointerAttempts: number;
  twoPointersMade: number;
  twoPointersPercentage: number;
  unsportsmanlikeFouls: number;
  minutesPlayed?: string;
  lastUpdateGameTime?: string;
  leftCornerStats: ZoneStatistics;
  rightCornerStats: ZoneStatistics;
  paintStats: ZoneStatistics;
  restrictedAreaStats: ZoneStatistics;
  midRangeStats: ZoneStatistics;
  aboveTheBreak3Stats: ZoneStatistics;
  backcourtStats: ZoneStatistics;
  pointsInThePaint?: number;
  timeWithLead?: number;
}

export interface Score {
  currentPeriod: string;
  currentClockTime: string;
  currentLineups: string;
  scorer: string;
}

export interface MatchScore {
  homeTeamScore: number;
  awayTeamScore: number;
  isTied: boolean;
}

export interface Period {
  periodNumber: number;
  periodSeq: number;
  label: string;
  startTimestamp?: string;
  endTimestamp?: string | null;
  score: MatchScore;
  periodScore: MatchScore;
}

export interface MatchSummaryTeam {
  id: string;
  teamName: string;
  teamColor: string;
  teamShortCode?: string | null;
  players: Player[];
}

export interface Substitution {
  playerIn: Player;
  playerOut: Player;
}

export interface Player {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  shirtNumber: string | null;
  isStartingSquad: boolean;
  isInCurrentLineup: boolean;
}

export interface PlayerActionAggregates extends ActionAggregatesBase {
  playerId: string;
}

export interface TeamActionAggregates extends ActionAggregatesBase {
  teamId: string;
  playerActionAggregates: PlayerActionAggregates[];
}

export interface ActionAggregatesBase {
  gameActionAggregates: GameActionAggregate[];
  periodActionAggregates: PeriodActionAggregate[];
}

export interface GameActionAggregate {
  period?: number;
  actionId: number;
  actionAggregate: number;
  subActionAggregates: GameSubActionAggregate[];
}

export interface PeriodActionAggregate {
  period: number;
  actionId: number;
  actionAggregate: number;
  subActionAggregates: GameSubActionAggregate[];
}

export interface ActionAggregates {
  ownerId: string;
  ownerName: string;
  gameActionAggregates: GameActionAggregate[];
}

export interface GameSubActionAggregate {
  subActionAggregate: number;
  subActionId: number;
}

export interface Actions {
  fixtureId?: string;
  actions: FixtureAction[];
}

export interface FixtureAction {
  id: string;
  actionId: string;
  slaBreachSummary?: SlaBreachSummary | null;
  scorerSeqNum: number;
  fixtureActionTypeId: number;
  hideFixtureActionTypeId?: number;
  fixtureSeqNum: number;
  sendTypeId: keyof typeof MATCH_ACTION_SEND_TYPE_NAME;
  scorerId: string;
  timestamp: string;
  timestampDt: string;
  fixtureId: string;
  period: number;
  clockTime: string;
  clockTimeString: string;
  clockTimeTicks: number | null;
  fixtureActionSubTypeId: number | null;
  parentFixtureActionId: string | null;
  relatedFixtureActionIds: string[];
  teamId: string | null;
  playerId: string | null;
  metadata?: any;
  flag: FixtureActionFlag | null;
  fixtureActionComments: FixtureActionComment[];
  score: MatchScore;
  periodScore?: MatchScore;
  sportSpecificFixtureState: SpecificFixtureState | null;
}

export interface SpecificFixtureState {
  yardLine: YardLine;
  downNumber?: number;
  gameState: number;
  yardsLeft?: number;
  summary: string;
}

export interface FixtureActionComment {
  id?: string;
  fixtureId: string;
  fixtureActionId?: string;
  comment?: string;
}

export interface FixtureActionFlag {
  fixtureActionId: FixtureAction['id'];
  state: FixtureActionFlagState;
}

export interface ScorerRatingBase {
  collectionId?: string | null;
  fixtureId: ScoringState['fixtureId'];
  requestTime: string;
  oktaId?: string | null;
  userId?: string;
}

export interface ScorerRatingsWithData extends ScorerRatingBase {
  comment?: string | null;
  quantityScore?: number;
  latencyScore?: number;
}

export interface CourtPosition {
  x: number;
  y: number;
  zone: string;
}

export interface ScorerInfoConfig {
  averageFor5LastFixtures: number | null;
  firstName: string;
  lastName: string;
  lastDataCollectionDate: string;
  numberOfCompletedMatches: number | null;

  userId: string;
}

export interface FixtureConfig {
  fixture: FixtureConfigFixture;
  homeSquad: FixtureConfigSquad;
  awaySquad: FixtureConfigSquad;
  fixtureOptions: FixtureConfigOptions;
  staticFixtureActions: StaticFixtureActionType[];
}

export interface NotificationsConfiguration {
  startTimeUtc: string;
  showNotificationBeforeKickOff: boolean;
  kickOffNotificationOffsetInMilliseconds: number;
  showNotificationAfterPeriod: boolean;
  periodNumber: number;
  afterPeriodNotificationDelayInMilliseconds: number;
}

export interface FixtureStream {
  liveStream: string | null;
  archivedStream: string | null;
  streamStartDateTime: string | null;
}

export interface PostMatchCheckAction {
  overallValue?: number | string;
  scorerIds?: string[];
  scorersValue?: number | string;
  supervisorId?: string | null;
  supervisorsValue?: number | string | null;
  isMismatch?: boolean;
}

export interface FixturePostMatchCheckStatistics {
  attack?: PostMatchCheckAction;
  blocked?: PostMatchCheckAction;
  corner?: PostMatchCheckAction;
  dangerousAttack?: PostMatchCheckAction;
  freeKick?: PostMatchCheckAction | null;
  goal?: PostMatchCheckAction;
  goalDisallowed?: PostMatchCheckAction | null;
  offside?: PostMatchCheckAction | null;
  penaltyAwarded?: PostMatchCheckAction | null;
  penaltyMissed?: PostMatchCheckAction;
  redCard?: PostMatchCheckAction;
  shotOffTarget?: PostMatchCheckAction;
  shotOnTarget?: PostMatchCheckAction;
  substitution?: PostMatchCheckAction;
  throwIn?: PostMatchCheckAction | null;
  yellowCard?: PostMatchCheckAction | null;
  isHome?: boolean | string;
  statsType: string;
  teamId?: FixtureConfigFixture['id'] | null;
  teamName?: FixtureConfigFixture['name'];
}

export interface FixturePostMatchCheck {
  externalApiTeamStatistics?: FixturePostMatchCheckStatistics[];
  externalStatisticsProviderUrl: string;
  internalTeamsStatistics: FixturePostMatchCheckStatistics[];
  statsDifference: FixturePostMatchCheckStatistics[];
}

export interface FixtureSupervisorCall {
  acknowledged: boolean;
  fixtureId: string;
  scorerId: string;
  timestamp: string;
  scorerFullName: string;
}

export interface FixtureScorerRating {
  id?: string;
  oktaId?: string | null;
  fixtureId?: null | string;
  collectionId?: null | string;
  comment?: null | string;
  latencyScore?: number;
  quantityScore?: number;
  overallScore?: number;
  serviceLevel?: number;
  user?: {
    deviceId?: null | string;
    email?: null | string;
    firstname?: null | string;
    lastname?: null | string;
    oktaId?: null | string;
    id?: string;
    scoringAppVersion?: null | string;
    telegramId?: null | string;
    scorerRatings?: FixtureScorerRating[] | [];
  } | null;
}

export interface FixtureComparisonRating {
  id: string;
  status: number;
  role: { id: number; name: string };
  user: {
    id: string;
    oktaId: null | string;
    telegramId: null | string;
    email: null | string;
    firstname: null | string;
    lastname: null | string;
    scoringAppVersion: null | string;
    deviceId: null | string;
    scorerRatings: FixtureScorerRating[];
  };
}

export interface FixtureSlaBreachChecklist {
  ruleName: string;
  ruleId: number;
  isBreached: boolean;
}

export interface SlaBreachSummary {
  latencyTicks: number;
  evaluationRuleId: number;
  evaluationReason: string | null;
  evaluationReasonNote?: string | null;
}

export interface SocketLatencyEvaluation {
  fixtureId: string;
  id: string;
  evaluationRuleId: number;
  collectionId?: string;
  latencyTicks: number;
  evaluationReason: string;
  evaluationReasonNote?: string | null;
  actionTimestamp: string;
}

export interface DeleteLatencyEvaluation {
  fixtureId: string;
  collectionId?: string;
  requestId: string;
  id: string;
}

export interface SlaBreachRules {
  ruleId: number;
  sportId: number;
  displayRuleName: string;
}

export interface StaticFixtureActionType {
  id: number;
  name: string;
}

export interface FixtureConfigFixture {
  id: string;
  name: string;
  status: string;
  startDateUTC: string;
  group: FixtureConfigGroup | null;
  stage: FixtureConfigStage;
  venue: FixtureConfigVenue;
  teams: FixtureConfigTeam[];
  type: FixtureTypeId;
  coverageLevel: number;
}

export interface FixtureConfigSquad {
  id: string;
  season: Pick<FixtureConfigSeason, 'id' | 'name'>;
  competition: Pick<FixtureConfigCompetition, 'id' | 'name'>;
  team: FixtureConfigTeam;
  players: Player[];
}

export interface FixtureConfigPlayer {
  id: string;
  squadId: string;
  locationId: number;
  shirtNumber: number;
  firstName: string;
  lastName: string;
  fullName: string;
}

export interface FixtureConfigGroup {
  id: string;
  name: string;
  stageId: string;
}

export interface FixtureConfigStage {
  id: string;
  name: string;
  season: FixtureConfigSeason;
  startDateUTC: number | string;
  endDateUTC: number | string;
}

export interface FixtureConfigVenue {
  id: string;
  name: string;
  location: FixtureConfigLocation;
}

export interface FixtureConfigTeam {
  id: string;
  name: string;
  category?: FixtureConfigCategory;
  club?: FixtureConfigClub;
}

export interface FixtureConfigSeason {
  id: string;
  name: string;
  startDateUTC?: number | string;
  endDateUTC?: number | string;
  competition: FixtureConfigCompetition;
}

export interface FixtureConfigCompetition {
  id: string;
  name: string;
  sport: FixtureConfigSport;
  category: FixtureConfigCategory;
  format: FixtureConfigFormat | null;
  tour: FixtureConfigTour | null;
  location: FixtureConfigLocation;
}

export interface FixtureConfigSport {
  id: number;
  name: string;
}

export interface FixtureConfigCategory {
  id: number;
  name: string;
}

export interface FixtureConfigFormat {
  id: number;
  name: string;
  sport: FixtureConfigSport;
}

export interface FixtureConfigTour {
  id: number;
  name: string;
  sport: FixtureConfigSport;
}
export interface FixtureConfigLocation {
  id: number;
  name: string;
  country: FixtureConfigCountry;
  continent: FixtureConfigContinent;
}
export interface FixtureConfigClub {
  id: string;
  name: string;
  location: FixtureConfigLocation;
}
export interface FixtureConfigCountry {
  id: string;
  name: string;
}

export interface FixtureConfigContinent {
  id: string;
  name: string;
}

export interface FixtureConfigOptions {
  sportRules: SportRules;
  actionButtons: ActionButton[];
}

export interface ActionButton {
  actionType: ActionType;
  changeableActionTypeIds?: number[];
  changeableActions?: ChangebleActions[];
  index: number;
  confirmation: boolean;
  startClock: boolean;
  stopClock: boolean;
  incrementScore: number;
  incrementScoreOtherTeam: number;
  gainPossesion: boolean;
  losePossesion: boolean;
  enabledInPossession: boolean;
  enabledOutOfPossession: boolean;
  playerRequired: boolean | number;
  maxLimits?: MaxLimits;
  changeableTeam?: boolean;
  subTypeRequired?: boolean;
}

export interface ChangebleActions {
  actionTypeId?: number;
  enabledWhen?: {
    confirmAction: boolean;
    editAction: boolean;
    editActions: boolean;
  };
}

export interface MaxLimits {
  perFixture?: {
    player: number;
  };
}

export interface ActionType {
  id: number;
  code?: string;
  name: string;
  subTypes?: ActionType[];
  usePlayersFromOtherTeam?: boolean;
  buttonLabel?: string;
}

export interface SportRules {
  clockDirection: string;
  periodDetails: PeriodDetails[];
  overtimeFormat: number;
  activePlayers: number;
  matchScoreType: SCORE_TYPE;
  disableActionTypeIdButtons?: number[];
  customPeriods?: boolean;
}

export interface PeriodDetails {
  seq: number;
  label: string;
  type: PERIOD_DETAILS_TYPE;
  disableEndingPeriodLimit?: number;
  initialClock?: number;
}

export enum PERIOD_DETAILS_TYPE {
  REGULAR = 0,
  OVERTIME = 1,
  PENALTY_SHOOTOUT = 2,
}
