import { ReactElement } from 'react';
import { Stack } from '@mui/material';
import { KeyboardButton } from '@/components/common/KeyboardButton';

export interface KeyBoardShortcutsCell {
  label: string;
  value: ReactElement;
  section?: string;
}

export const SHORTCUTS_SECTION = {
  BASIC: 'General',
  NAVBAR_NAVIGATION: 'Navbar Navigation',
  TABLE_MANIPULATION: 'Table Manipulation',
};

export const KeyboardShortcutCells: KeyBoardShortcutsCell[] = [
  {
    label: 'Open/Close action evaluation popup for SLA Breach Table',
    value: (
      <Stack flexDirection='row'>
        <KeyboardButton theKey='mouse hover' /> + <KeyboardButton theKey='l' />
      </Stack>
    ),
    section: SHORTCUTS_SECTION.TABLE_MANIPULATION,
  },
  {
    label: 'Open/Close edit action popup for Match Data Table',
    value: (
      <Stack flexDirection='row'>
        <KeyboardButton theKey='mouse hover' /> + <KeyboardButton theKey='e' />
      </Stack>
    ),
    section: SHORTCUTS_SECTION.TABLE_MANIPULATION,
  },
  {
    label: 'Assign incident resolver for Super Admin View Table',
    value: (
      <Stack flexDirection='row'>
        <KeyboardButton theKey='mouse hover' /> + <KeyboardButton theKey='a' />
      </Stack>
    ),
    section: SHORTCUTS_SECTION.TABLE_MANIPULATION,
  },
  {
    label: 'Unassign incident resolver for Super Admin View Table',
    value: (
      <Stack flexDirection='row'>
        <KeyboardButton theKey='mouse hover' /> + <KeyboardButton theKey='u' />
      </Stack>
    ),
    section: SHORTCUTS_SECTION.TABLE_MANIPULATION,
  },
  {
    label: 'Go to collection from Super Admin View Table',
    value: (
      <Stack flexDirection='row'>
        <KeyboardButton theKey='mouse hover' /> + <KeyboardButton theKey='g' />
      </Stack>
    ),
    section: SHORTCUTS_SECTION.TABLE_MANIPULATION,
  },

  {
    label: 'Open/Close preferences',
    value: <KeyboardButton theKey='p' />,
    section: SHORTCUTS_SECTION.BASIC,
  },
  {
    label: 'Open/Close filters drawer',
    value: <KeyboardButton theKey='f' />,
    section: SHORTCUTS_SECTION.TABLE_MANIPULATION,
  },
  {
    label: 'Open/Close checklist drawer',
    value: <KeyboardButton theKey='c' />,
    section: SHORTCUTS_SECTION.NAVBAR_NAVIGATION,
  },
  {
    label: 'Open/Close data review drawer',
    value: <KeyboardButton theKey='m' />,
    section: SHORTCUTS_SECTION.NAVBAR_NAVIGATION,
  },
  {
    label: 'Show/Hide fixture info',
    value: <KeyboardButton theKey='i' />,
    section: SHORTCUTS_SECTION.NAVBAR_NAVIGATION,
  },
  {
    label: 'Show/Hide data collectors',
    value: <KeyboardButton theKey='d' />,
    section: SHORTCUTS_SECTION.NAVBAR_NAVIGATION,
  },
  {
    label: 'Show/Hide scoreboard',
    value: <KeyboardButton theKey='s' />,
    section: SHORTCUTS_SECTION.NAVBAR_NAVIGATION,
  },
  {
    label: 'Change dark/light theme mode',
    value: <KeyboardButton theKey='t' />,
    section: SHORTCUTS_SECTION.BASIC,
  },
  {
    label: 'Go to Home/Super Admin page',
    value: <KeyboardButton theKey='v' />,
    section: SHORTCUTS_SECTION.BASIC,
  },
];
