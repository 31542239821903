import { Stack, Tooltip, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AddIcon from '@mui/icons-material/Add';
import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import {
  FixturesTableCell,
  FixturesTableCellProps,
} from '@/components/FixturesTable/common/FixturesTableCell';
import { FixturesTableContext } from '@/components/FixturesTable/context/FixturesTableContext';
import { Collection } from '@/contexts/fixturesWithIncidents/types';
import { COMMON_STRING } from '@/constants/dictionary';
import { KeyboardButton } from '@/components/common/KeyboardButton';
import { ARIA_LABEL } from '@/constants/ariaLabel';

interface AvatarCellProps extends FixturesTableCellProps {
  collection: Collection;
}

const AvatarTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: '.75rem',
}));

export const AvatarCell = ({ collection, isRowOpened }: AvatarCellProps) => {
  const {
    actions: { assignMeHandler, unAssignMeHandler },
  } = useContext(FixturesTableContext);
  const username = collection.resolver?.fullName;
  const isAssigned = collection.resolver?.resolvedOn === null;

  const generateAssignedUserInitials = (username: string) => {
    if (!username) return '';
    const [[first], [second]] = username.split(' ');
    return first + second;
  };

  const onClick = () => {
    if (isAssigned) {
      return unAssignMeHandler([collection.collectionId]);
    } else {
      return assignMeHandler([collection.collectionId]);
    }
  };

  const generateTooltipTitle = () => {
    if (isAssigned) {
      return <AvatarTitleTypography>{username}</AvatarTitleTypography>;
    } else {
      return (
        <Stack gap={1}>
          <AvatarTitleTypography>
            {COMMON_STRING.ASSIGN_ME} <KeyboardButton theKey='a' />
          </AvatarTitleTypography>
          {username && (
            <AvatarTitleTypography>
              {COMMON_STRING.PREVIOUS_INCIDENT_RESOLVER}: {username}
            </AvatarTitleTypography>
          )}
        </Stack>
      );
    }
  };

  return (
    <FixturesTableCell isRowOpened={isRowOpened}>
      <Tooltip title={generateTooltipTitle()} arrow placement='bottom'>
        <Avatar
          sx={{
            bgcolor: (theme) =>
              isAssigned
                ? theme.palette.fixturesTable.avatar.background
                : 'transparent',
            color: (theme) =>
              isAssigned
                ? theme.palette.fixturesTable.avatar.textColor
                : theme.palette.avatar.border,
            border: isAssigned
              ? 'none'
              : (theme) => `2px dashed ${theme.palette.avatar.border}`,
            width: 40,
            height: 40,
            fontSize: 16,
            '&:hover': {
              backgroundColor: (theme) =>
                theme.palette.fixturesTable.avatar.background,
              cursor: 'pointer',
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
          aria-label={ARIA_LABEL.AVATAR}
        >
          {isAssigned && username ? (
            generateAssignedUserInitials(username)
          ) : (
            <AddIcon />
          )}
        </Avatar>
      </Tooltip>
    </FixturesTableCell>
  );
};
