import { PropsWithChildren } from 'react';
import { Grid, GridProps, Stack } from '@mui/material';

interface FixturesDetailsGridItemProps extends PropsWithChildren {
  gridProps?: GridProps;
}

export const FixturesDetailsGridItem = ({
  gridProps,
  children,
}: FixturesDetailsGridItemProps) => {
  return (
    <Grid item xs={2} {...gridProps}>
      <Stack gap={1}>{children}</Stack>
    </Grid>
  );
};
