import { FC } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { CheckboxMenuItem } from '@/components/FixturesTable/CheckboxActionMenu/CheckboxMenuItems/CheckboxMenuItem';
import { COMMON_STRING } from '@/constants/dictionary';
import { Collection } from '@/contexts/fixturesWithIncidents/types';

interface CheckboxUnassignMeItemProps {
  deselectHandler: VoidFunction;
  selectedItems: Collection['collectionId'][];
  unAssignMeHandler: (collectionIds: Collection['collectionId'][]) => void;
}

export const CheckboxUnassignMeItem: FC<CheckboxUnassignMeItemProps> = ({
  deselectHandler,
  selectedItems,
  unAssignMeHandler,
}) => {
  const onClickHandler = () => {
    unAssignMeHandler(selectedItems);
    deselectHandler();
  };

  return (
    <CheckboxMenuItem
      text={COMMON_STRING.UNASSIGN_ME}
      icon={<ClearIcon />}
      onClick={onClickHandler}
    />
  );
};
