import { FC } from 'react';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import { CheckboxMenuItem } from '@/components/FixturesTable/CheckboxActionMenu/CheckboxMenuItems/CheckboxMenuItem';
import { COMMON_STRING } from '@/constants/dictionary';

interface CheckboxDeselectAllItemProps {
  deselectHandler: VoidFunction;
}

export const CheckboxDeselectAllItem: FC<CheckboxDeselectAllItemProps> = ({
  deselectHandler,
}) => {
  return (
    <CheckboxMenuItem
      text={COMMON_STRING.DESELECT_ALL}
      icon={<RemoveDoneIcon />}
      onClick={deselectHandler}
    />
  );
};
