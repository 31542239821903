import { useSnackbar } from 'notistack';
import useSWR from 'swr';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { FixturesWithIncidents } from '@/contexts/fixturesWithIncidents/types';
import { ApiError } from '../ApiError';
import { HTTP_STATUS_CODE } from '../constants';
import {
  SERVICE_ENDPOINT,
  fetchFromMTService,
} from '../fetcher/monitoringToolService';

interface UseFixturesWithIncidentsParams {
  refreshInterval: number; //time in ms
  queryParams?: {
    limit?: number;
    page?: number;
    competitionId?: string[];
    rank?: string[];
    sportId?: number[];
    ft?: string;
    telegramAdminId?: string[];
    supervisorId?: string[];
    workstationId?: number[];
  };
}

export const useFixturesWithIncidents = ({
  refreshInterval,
  queryParams,
}: UseFixturesWithIncidentsParams) => {
  const { enqueueSnackbar } = useSnackbar();
  const token = useAuthToken();

  const { data, error, mutate } = useSWR<FixturesWithIncidents, ApiError>(
    token
      ? {
          token,
          endpoint:
            SERVICE_ENDPOINT.FixturesWithIncidents().CollectionsIssuesSummary
              .GET,
          queryParams,
        }
      : null,
    fetchFromMTService,
    {
      onErrorRetry: (e, _, config, revalidate, { retryCount }) => {
        if (
          e.status !== HTTP_STATUS_CODE.NOT_FOUND &&
          e.status !== HTTP_STATUS_CODE.UNAUTHORIZED
        ) {
          return enqueueSnackbar(e.title, { variant: 'error' });
        }
        if (e.status === HTTP_STATUS_CODE.NOT_FOUND) return;
        if (e.status === HTTP_STATUS_CODE.UNAUTHORIZED) return;
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
      revalidateOnFocus: false,
      refreshInterval,
    },
  );

  return {
    fixturesWithIncidents: data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};
