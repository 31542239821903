import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FixtureIssue } from '@/contexts/fixturesWithIncidents/types';
import { convertSecondsToTimeWithHours } from '@/utils/convertSecondsToTime/convertSecondsToTime';
import { COMMON_STRING } from '@/constants/dictionary';

interface TimeSinceReportedProps {
  issueWithMainIncident: FixtureIssue | undefined;
}

export const timeSinceReportedDataTestId = 'time-since-reported-data-test-id';

export const TimeSinceReported = ({
  issueWithMainIncident,
}: TimeSinceReportedProps) => {
  const [timeDuration, setTimeDuration] = useState<number>(-1); //time in sec
  const [countdownText, setCountdownText] = useState<string>(
    COMMON_STRING.NOT_AVAILABLE,
  );

  useEffect(() => {
    if (!issueWithMainIncident) {
      return setTimeDuration(-1);
    } else {
      setTimeDuration(
        +(
          (new Date().getTime() -
            new Date(issueWithMainIncident.reportedOn as Date).getTime()) /
          1000
        ).toFixed(),
      );
    }
  }, [issueWithMainIncident]);

  useEffect(() => {
    if (timeDuration < 0) {
      return;
    }

    const intervalId = setInterval(() => {
      setTimeDuration((prev) => {
        const newTimeDuration = prev + 1;

        if (newTimeDuration <= 0) {
          clearInterval(intervalId);
        }

        return +newTimeDuration.toFixed();
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeDuration]);

  useEffect(() => {
    if (timeDuration < 0) {
      return setCountdownText(COMMON_STRING.NOT_AVAILABLE);
    }
    setCountdownText(convertSecondsToTimeWithHours(timeDuration));
  }, [timeDuration]);

  return (
    <Typography
      variant='body2'
      color={
        countdownText === COMMON_STRING.NOT_AVAILABLE ? 'inherit' : 'error'
      }
      data-testid={timeSinceReportedDataTestId}
    >
      {countdownText}
    </Typography>
  );
};
