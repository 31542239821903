import {
  COLLECTION_RANK_IDS,
  COLLECTION_RANK_NAMES,
} from '@/service/constants';

export enum IssueSeverity {
  None,
  Medium,
  Critical,
}

export enum Issue {
  SCORER_DISCONNECTED = 1,
  COLL_STAT_SUSPENDED = 2,
  PLAY_STOPPED = 3,
  COLLECTION_STATUS = 4,
  FLAGS_UNCONFIRMED = 5,
  OUTDATED_APP_VERSION = 6,
  MATCH_NOT_LIVE = 7,
  NO_COMMENT_ON_ACTIONS = 8,
  GDS_NOT_ACKNOWLEDGED_PRESENCE = 9,
  MARKET_CLOSED = 10,
}

export interface SummaryIssue {
  issueId: Issue;
  totalIssuesCount: number;
}

export interface RequiresAction {
  id: IssueSeverity;
  name: string;
  displayName: string;
}

export interface FixtureIssue {
  id: Issue;
  name: string;
  reportedOn: Date;
  requiresAction: RequiresAction;
}

export interface CollectionRank {
  id: COLLECTION_RANK_IDS;
  name: string;
  displayName: string;
}

export interface Scorer {
  userId: string;
  appVersion: string;
  isConnected: boolean;
  fullName: string;
  collectionId: string;
}

export interface Resolver {
  fullName: string;
  resolvedOn: null | string;
  userId: string;
}

export interface Collection {
  fixtureId: string;
  collectionId: string;
  name: string;
  rank: COLLECTION_RANK_NAMES;
  competitionName: string;
  workstationName: string;
  supervisorName: string;
  supervisorId: string;
  scorers: Scorer[];
  resolver: Resolver | null;
  issues: FixtureIssue[];
}

export interface Pagination {
  page: number;
  limit: number;
  results: number;
}

export interface FixturesWithIncidents {
  collections: {
    data: Collection[];
    pagination: Pagination;
  };
  summary: SummaryIssue[];
}

export interface FilterWithStringId {
  id: string;
  name: string;
}

export interface FilterWithNumberId {
  id: number;
  name: string;
}
