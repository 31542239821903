import axiosObject, { AxiosInstance } from 'axios';
import { AccessToken } from '@okta/okta-auth-js';
import envVariables from '@/envVariables';
import {
  onResponseFulfilled,
  onResponseRejected,
} from '@/service/interceptors/response';

const configureAxios = (
  axios: AxiosInstance,
  baseUrl = envVariables.mtApiUrl,
) => {
  axios.defaults.baseURL = baseUrl;
  axios.defaults.headers.common['Accept'] = 'application/json';
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.validateStatus = (status) => {
    return status < 400;
  };
  axios.interceptors.response.use(onResponseFulfilled, onResponseRejected);
};

export const axios = axiosObject.create();
configureAxios(axios);

export const addAccessTokenToApiHeaders = (accessToken?: AccessToken) => {
  addAuthorizationHeader(axios, accessToken);
};

const addAuthorizationHeader = (
  axios: AxiosInstance,
  accessToken?: AccessToken,
) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${accessToken?.accessToken}`;
};
