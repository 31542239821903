import { COMMON_STRING } from '@/constants/dictionary';
import { RadioLabelsType } from './types';

export const FILTERS_HISTORY_MAX_LENGTH = 10;

export const FILTER_PROPERTY = {
  ACTION_ID: 'actionId',
  SEND_TYPE_ID: 'sendTypeId',
  PERIOD: 'period',
  FIXTURE_ACTION_TYPE_ID: 'fixtureActionTypeId',
  TEAM_ID: 'teamId',
  PLAYER_ID: 'playerId',
  FLAG: 'flag',
  COMMENT: 'fixtureActionComments',
  SCORER_ID: 'scorerId',
} as const;

export const NON_PROPERTY_FILTER = {
  HIDE_UPDATED_ACTIONS: 'hideUpdatedActions',
  LATENCY: 'latency',
  MISTAKE: 'mistake',
} as const;

export const FILTER_DISPLAY_NAME = {
  ACTION_ID: 'ActionID',
  SEND_TYPE_ID: COMMON_STRING.SEND_TYPE,
  PERIOD: COMMON_STRING.PERIOD,
  FIXTURE_ACTION_TYPE_ID: COMMON_STRING.ACTION,
  TEAM_ID: COMMON_STRING.TEAM,
  PLAYER_ID: COMMON_STRING.PLAYER,
  FLAG: 'Flag',
  HIDE_FIXTURE_ACTION_TYPE_ID: 'Hide Action',
  COMMENT: COMMON_STRING.COMMENT,
  ACTIONS_UPDATED: 'Actions updated',
  LATENCY: COMMON_STRING.LATENCY,
  MISTAKE: COMMON_STRING.MISTAKE,
  SCORER: COMMON_STRING.SCORER,
};

export const FILTER_DISPLAY_VALUE = {
  COMMENT: {
    WITH_COMMENT: 'With Comment',
    WITH_OUT_COMMENT: 'Without Comment',
  },
  FLAG: { FLAGGED: 'Flagged', NOT_FLAGGED: 'Not Flagged' },
  LATENCY: {
    WITH_LATENCY: 'With Latency',
    WITH_OUT_LATENCY: 'Without Latency',
  },
  MISTAKE: {
    WITH_MISTAKE: 'With Mistakes',
    WITH_OUT_MISTAKE: 'Without Mistakes',
  },
};

export type FilterProperty =
  (typeof FILTER_PROPERTY)[keyof typeof FILTER_PROPERTY];

export const GENERIC_FILTER_VALUE = {
  UNSET: '',
  FALSY: 'falsy',
  TRUTHY: 'truthy',
  UNKNOWN: 'unknown',
} as const;
export type GenericFilterValue =
  (typeof GENERIC_FILTER_VALUE)[keyof typeof GENERIC_FILTER_VALUE];

export const FILER_TEMPLATE_COLUMN_QUANTITY = 3;

export const RADIO_FLAG_LABELS: RadioLabelsType = {
  unset: COMMON_STRING.ALL,
  falsy: FILTER_DISPLAY_VALUE.FLAG.NOT_FLAGGED,
  truthy: FILTER_DISPLAY_VALUE.FLAG.FLAGGED,
};

export const RADIO_COMMENT_LABELS: RadioLabelsType = {
  unset: COMMON_STRING.ALL,
  falsy: FILTER_DISPLAY_VALUE.COMMENT.WITH_OUT_COMMENT,
  truthy: FILTER_DISPLAY_VALUE.COMMENT.WITH_COMMENT,
};

export const RADIO_PLAYER_LABELS: RadioLabelsType = {
  unset: COMMON_STRING.ALL,
  falsy: COMMON_STRING.NONE,
  unknown: COMMON_STRING.UNKNOWN,
};

export const RADIO_ACTIONS_UPDATED: RadioLabelsType = {
  falsy: COMMON_STRING.ALL,
  truthy: COMMON_STRING.ONLY_NOT_UPDATED,
};

export const RADIO_SLA_LATENCY: RadioLabelsType = {
  unset: COMMON_STRING.ALL,
  truthy: FILTER_DISPLAY_VALUE.LATENCY.WITH_LATENCY,
  falsy: FILTER_DISPLAY_VALUE.LATENCY.WITH_OUT_LATENCY,
};

export const RADIO_SLA_MISTAKE: RadioLabelsType = {
  unset: COMMON_STRING.ALL,
  truthy: FILTER_DISPLAY_VALUE.MISTAKE.WITH_MISTAKE,
  falsy: FILTER_DISPLAY_VALUE.MISTAKE.WITH_OUT_MISTAKE,
};

export const RADIO_SCORER_LABELS: RadioLabelsType = {
  falsy: COMMON_STRING.ALL,
  unknown: COMMON_STRING.SUPERVISOR,
};
