import { FIXTURES_FILTER_PROPERTY } from '@/components/FixturesTable/FixturesFilters/constants';
import { FixturesFilter } from '@/components/FixturesTable/FixturesFilters/types';

export const parseSelectedFilters = (
  filtersList: FixturesFilter[],
  ftSearch: string,
) => {
  const filtersProperties = Object.values(FIXTURES_FILTER_PROPERTY);

  const allQueries = Object.assign(
    {},
    ...filtersProperties.map((filter) => ({
      [filter]: filtersList
        .filter((activeFilter) => activeFilter.property === (filter as string))
        ?.map((activeFilter) => activeFilter.value),
      ft: ftSearch,
    })),
  );

  return Object.fromEntries(
    Object.entries(allQueries).filter(([_, v]) => (v as string).length !== 0),
  );
};
