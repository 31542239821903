import { Checkbox, IconButton, Stack, TableRow } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MouseEvent, useState } from 'react';
import MoreVert from '@mui/icons-material/MoreVert';
import { SuccessIndicator } from '@/components/common/SuccessIndicator';
import {
  Collection,
  IssueSeverity,
} from '@/contexts/fixturesWithIncidents/types';
import { FixturesTableRowDetails } from '@/components/FixturesTable/FixturesTableRowDetails';
import { FixturesTableCell } from '@/components/FixturesTable/common/FixturesTableCell';
import { AvatarCell } from '@/components/FixturesTable/common/AvatarCell';
import {
  BUTTON_NAME,
  TABLE_CLASS_NAMES,
} from '@/components/ActionsTable/constants';
import { FIXTURE_TABLE_HEADCELLS } from '@/components/FixturesTable/constants';
import { ARIA_LABEL } from '@/constants/ariaLabel';

interface FixturesTableRowProps {
  selectedRows: string[];
  collection: Collection;
  selectRow: (event: React.MouseEvent<unknown>, id: string) => void;
  setHoverCollection: (collection: Collection | undefined) => void;
}

export const FixturesTableRow = ({
  collection,
  selectRow,
  selectedRows,
  setHoverCollection,
}: FixturesTableRowProps) => {
  const [isRowOpened, setIsRowOpened] = useState(false);
  const isSelected = (id: string) => selectedRows.indexOf(id) !== -1;
  const isItemSelected = isSelected(collection.collectionId);
  const labelId = `fixtures-table-checkbox-${collection.collectionId}`;
  const issueColumns = FIXTURE_TABLE_HEADCELLS.filter(
    (headCell) => headCell.issueIndex !== undefined,
  );

  const onRowClick = (event: MouseEvent<HTMLTableRowElement>) => {
    const path = event.nativeEvent.composedPath() as HTMLElement[];

    const button = path.find(({ tagName }) => tagName === 'BUTTON') as
      | HTMLButtonElement
      | undefined;

    if (button && button.name === BUTTON_NAME.MORE) {
      return;
    }
    setIsRowOpened(!isRowOpened);
  };

  return (
    <>
      <TableRow
        tabIndex={-1}
        key={collection.fixtureId}
        sx={{
          '& > *': { borderBottom: 'unset' },
          height: (theme) => theme.spacing(2),
        }}
        hover
        role='checkbox'
        className={`${TABLE_CLASS_NAMES.ROW}`}
        data-collection-id={collection.collectionId}
        onMouseOver={() => setHoverCollection(collection)}
        onMouseOut={() => setHoverCollection(undefined)}
        onClick={onRowClick}
      >
        <FixturesTableCell
          props={{ padding: 'checkbox' }}
          isRowOpened={isRowOpened}
        >
          <Checkbox
            color='primary'
            checked={isItemSelected}
            onClick={(event) => {
              event.stopPropagation();
              selectRow(event, collection.collectionId);
            }}
            inputProps={{
              'aria-labelledby': labelId,
              'aria-label': ARIA_LABEL.FIXTURES_CHECKBOX,
            }}
          />
        </FixturesTableCell>
        <FixturesTableCell isRowOpened={isRowOpened}>
          <IconButton size='small'>
            {isRowOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </FixturesTableCell>
        <FixturesTableCell isRowOpened={isRowOpened}>
          {collection.name}
        </FixturesTableCell>
        <AvatarCell isRowOpened={isRowOpened} collection={collection} />
        {issueColumns.map(({ issueIndex, issueId }) => (
          <FixturesTableCell
            isRowOpened={isRowOpened}
            key={`issue-${issueIndex}`}
          >
            <Stack alignItems='center'>
              <SuccessIndicator
                issueSeverity={
                  collection.issues.find((issue) => issue.id === issueId)
                    ?.requiresAction.id || IssueSeverity.None
                }
              />
            </Stack>
          </FixturesTableCell>
        ))}

        <FixturesTableCell isRowOpened={isRowOpened} props={{ align: 'right' }}>
          <IconButton name={BUTTON_NAME.MORE} size='small' aria-label='more'>
            <MoreVert fontSize='small' />
          </IconButton>
        </FixturesTableCell>
      </TableRow>
      <FixturesTableRowDetails collection={collection} opened={isRowOpened} />
    </>
  );
};
