import { FC, useContext, useEffect, useState } from 'react';
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
  restrictToHorizontalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import { PreferencesContext } from '@/contexts/preferences/context';
import { TemplateFormData, TemplateDragDirection } from '../types';
import { TemplateSwitcherItem } from '../TemplateSwitcher/TemplateSwitcherItem';
import { DisplayTemplateAccordion } from '../DisplayTemplateAccordion';
import { SortableTemplateItem } from './SortableTemplateItem';

interface SortableTemplateListProps {
  templates: TemplateFormData[] | undefined;
  dragDirection: TemplateDragDirection;
  accordionProps?: {
    setAccordionOpen: (value: string | undefined) => void;
    editFromDrawer?: boolean;
    accordionOpen: string | undefined;
    onEdit: (id: string, sportId: number) => void;
    onDelete: (id: string, sportId: number) => void;
  };
  selectedTemplateId?: string | undefined;
}

export const SortableTemplateList: FC<SortableTemplateListProps> = ({
  templates,
  dragDirection,
  accordionProps,
  selectedTemplateId,
}) => {
  const {
    actions: { reorderTemplates },
  } = useContext(PreferencesContext);

  const [orderedTemplates, setOrderedTemplates] = useState(templates);

  useEffect(() => {
    setOrderedTemplates(templates);
  }, [templates]);

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 1,
    },
  });
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(
    mouseSensor,
    touchSensor,
    keyboardSensor,
    pointerSensor,
  );

  if (!templates) {
    return null;
  }

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = templates.findIndex((item) => item.id === active.id);
      const newIndex = templates.findIndex((item) => item.id === over.id);

      const reorderedTemplate = arrayMove(templates, oldIndex, newIndex);
      setOrderedTemplates(reorderedTemplate);
      reorderTemplates(reorderedTemplate);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[
        restrictToParentElement,
        dragDirection === TemplateDragDirection.Horizontal
          ? restrictToHorizontalAxis
          : restrictToVerticalAxis,
      ]}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={orderedTemplates ?? []}
        strategy={
          dragDirection === TemplateDragDirection.Horizontal
            ? horizontalListSortingStrategy
            : verticalListSortingStrategy
        }
      >
        {orderedTemplates?.map((template) =>
          dragDirection === TemplateDragDirection.Horizontal ? (
            <SortableTemplateItem key={template.id} id={template.id}>
              <TemplateSwitcherItem template={template} key={template.id} />
            </SortableTemplateItem>
          ) : (
            <SortableTemplateItem
              key={template.id}
              id={template.id}
              withDragHandle
            >
              <DisplayTemplateAccordion
                template={template}
                selectedTemplateId={selectedTemplateId}
                {...accordionProps!}
              />
            </SortableTemplateItem>
          ),
        )}
      </SortableContext>
    </DndContext>
  );
};
