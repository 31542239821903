import {
  Checkbox,
  CheckboxProps,
  CircularProgress,
  FormControlLabel,
} from '@mui/material';
import { useState } from 'react';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import { FixtureSlaBreachChecklist } from '@/service/types';

interface SlaBreachCheckboxProps extends CheckboxProps {
  slaBreachChecklistElement: FixtureSlaBreachChecklist;
  onBreachHandler: (checklistElement: FixtureSlaBreachChecklist) => void;
}

export const SlaBreachCheckbox = ({
  slaBreachChecklistElement,
  disabled,
  onBreachHandler,
}: SlaBreachCheckboxProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const onChangeHandler = async (
    checklistElement: FixtureSlaBreachChecklist,
  ) => {
    await setIsLoading(true);
    await onBreachHandler(checklistElement);
    await setIsLoading(false);
  };

  return (
    <FormControlLabel
      key={slaBreachChecklistElement.ruleId}
      control={
        <Checkbox
          disabled={disabled}
          indeterminate={isLoading}
          indeterminateIcon={<CircularProgress size='1.5em' />}
          checked={slaBreachChecklistElement.isBreached}
          icon={<CheckBoxOutlineBlank color={'inherit'} />}
          onChange={() => onChangeHandler(slaBreachChecklistElement)}
          name={slaBreachChecklistElement.ruleName}
        />
      }
      label={slaBreachChecklistElement.ruleName}
      sx={{
        '& .MuiFormControlLabel-label.Mui-disabled': { color: 'inherit' },
        width: 'fit-content',
      }}
    />
  );
};
