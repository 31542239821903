import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import { useTheme } from '@mui/material';
import { Icon } from '@mdi/react';
import {
  mdiAccountSwitchOutline,
  mdiAlertRhombusOutline,
  mdiHandExtendedOutline,
  mdiBasketball,
  mdiAccountOutline,
  mdiHockeySticks,
} from '@mdi/js';
import { SOCCER_SUMMARY_STATS_ACTION_NAME } from '@/service/utils/getSoccerSummaryStats';
import { CardIcon } from '@/components/ActionsTable/ActionIcons';
import { BasketNotUpdatedActions } from '@/service/utils/getBasketActionStats';
import { HockeytNotUpdatedActions } from '@/service/utils/getHockeyActionStats';
import { THEME_MODE } from '@/contexts/theme/constants';

export const NOT_UPDATED_ACTION_STATS_NAME = {
  POSSIBLE_ASSISTS: 'Possible Assists',
  POSSIBLE_REBOUNDS: 'Possible Rebounds',
  UNKNOWN_PLAYER: 'Unknown Player',
  POSSIBLE_GOALKEEPER_SAVES: 'Possible Goalkeeper Saves',
};

const ICON_LABELS = {
  UNKNOWN_PLAYER: 'unknown-player-icon',
  POSSIBLE_REBOUNDS: 'possible-rebounds-icon',
  POSSIBLE_ASSISTS: 'possible-assists-icon',
  POSSIBLE_GOALKEEPER_SAVES: 'possible-goalkeeper-saves-icon',
};

const commonIconStyle = {
  width: '0.85em',
  height: '1.3em',
  fontSize: '0.85em',
};

export const StatsCornerIcon = () => {
  const theme = useTheme();
  return (
    <svg width={'1.1em'} height={'1.1em'} viewBox='0 0 14 14' version='1.1'>
      <g id='Atom-/-Icon-/-Corner-' stroke='none' strokeWidth='1.4' fill='none'>
        <g
          id='Group'
          transform='translate(1.5, 1.5)'
          stroke={theme.palette.svgColors.main}
        >
          <polyline id='Path-4' points='0 0 0 11 11 11'></polyline>
          <path
            d='M5.80932785,10.9320988 C5.80932785,7.72369559 3.20840318,5.12277092 2.19643234e-12,5.12277092'
            id='Path'
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const statsIconItems = (name: string, hasMismatch: boolean) => {
  const icons = {
    [SOCCER_SUMMARY_STATS_ACTION_NAME.RED_CARDS]: (
      <CardIcon
        sx={{
          bgcolor: (theme) => theme.palette.iconsColors.redCard,
          ...commonIconStyle,
        }}
      />
    ),
    [SOCCER_SUMMARY_STATS_ACTION_NAME.YELLOW_CARDS]: (
      <CardIcon
        sx={{
          border: (theme) =>
            theme.palette.mode === THEME_MODE.LIGHT && hasMismatch
              ? '0.1em solid #d6997f'
              : 'none',
          bgcolor: (theme) => theme.palette.iconsColors.yellowCard,
          ...commonIconStyle,
        }}
      />
    ),
    [SOCCER_SUMMARY_STATS_ACTION_NAME.CORNERS_TAKEN]: <StatsCornerIcon />,
    [SOCCER_SUMMARY_STATS_ACTION_NAME.SUBSTITUTIONS]: (
      <Icon size={'1.1em'} path={mdiAccountSwitchOutline} />
    ),
    [SOCCER_SUMMARY_STATS_ACTION_NAME.GOALS]: (
      <SportsSoccerIcon sx={{ fontSize: '1.1em' }} />
    ),
    [SOCCER_SUMMARY_STATS_ACTION_NAME.DANGEROUS_ATTACKS]: (
      <Icon size={'1.1em'} path={mdiAlertRhombusOutline} />
    ),
  };
  return icons[name];
};

export const basketStatsIconItems = (name: string) => {
  const icons = {
    [NOT_UPDATED_ACTION_STATS_NAME.POSSIBLE_ASSISTS]: (
      <Icon
        aria-label={ICON_LABELS.POSSIBLE_ASSISTS}
        path={mdiHandExtendedOutline}
        size={'1.1em'}
      />
    ),
    [NOT_UPDATED_ACTION_STATS_NAME.POSSIBLE_REBOUNDS]: (
      <Icon
        aria-label={ICON_LABELS.POSSIBLE_REBOUNDS}
        path={mdiBasketball}
        size={'1.1em'}
      />
    ),
    [NOT_UPDATED_ACTION_STATS_NAME.UNKNOWN_PLAYER]: (
      <Icon
        aria-label={ICON_LABELS.UNKNOWN_PLAYER}
        path={mdiAccountOutline}
        size={'1.1em'}
      />
    ),
  };
  return icons[name];
};

export const hockeyStatsIconItems = (name: string) => {
  const icons = {
    [NOT_UPDATED_ACTION_STATS_NAME.POSSIBLE_ASSISTS]: (
      <Icon
        aria-label={ICON_LABELS.POSSIBLE_ASSISTS}
        path={mdiHandExtendedOutline}
        size={'1.1em'}
      />
    ),
    //toDo change ICON when design appears
    [NOT_UPDATED_ACTION_STATS_NAME.POSSIBLE_GOALKEEPER_SAVES]: (
      <Icon
        aria-label={ICON_LABELS.POSSIBLE_GOALKEEPER_SAVES}
        path={mdiHockeySticks}
        size={'1.1em'}
      />
    ),
    [NOT_UPDATED_ACTION_STATS_NAME.UNKNOWN_PLAYER]: (
      <Icon
        aria-label={ICON_LABELS.UNKNOWN_PLAYER}
        path={mdiAccountOutline}
        size={'1.1em'}
      />
    ),
  };
  return icons[name];
};

export const getInitialState = (
  isBasket?: boolean,
): BasketNotUpdatedActions | HockeytNotUpdatedActions => {
  const commonState = { possibleAssists: [], unknownPlayers: [] };

  return isBasket
    ? { ...commonState, possibleRebounds: [] }
    : { ...commonState, possibleGoalkeeperSaves: [] };
};
