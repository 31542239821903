import { FC, MouseEvent, useCallback, useContext, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import {
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  Tooltip,
  styled,
  ListItemTextProps,
} from '@mui/material';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import Settings from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { useOktaAuth } from '@okta/okta-react';
import Menu, { MenuProps } from '@mui/material/Menu';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import { grey } from '@mui/material/colors';
import { ThemeToggle } from '@/components/ThemeToggle';
import { DICTIONARY } from '@/constants/dictionary';
import { PreferencesDialog } from '@/components/PreferencesDialog/PreferencesDialog';
import { UIStateContext } from '@/contexts/UIState/context';
import { PANEL } from '@/contexts/UIState/types';
import { SuperAdminViewMenuItem } from '@/components/Navbar/SuperAdminViewMenuItem';
import { KeyboardButton } from '../common/KeyboardButton';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';
import { getKey, getOS } from '../KeyboardShortcuts/utils';
import { ShortcutDialog } from '../ShortcutDialog/ShortcutDialog';
import { SETTING_BUTTON_LABEL } from '.';

export const ListItemTextStyled: FC<ListItemTextProps> = (props) => (
  <ListItemText
    disableTypography
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      flexGrow: 1,
    }}
    {...props}
  />
);

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    autoFocus={false}
    elevation={10}
    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiMenu-list': {
    padding: 0,
  },
  '& .MuiMenuItem-root': {
    padding: theme.spacing(1.5, 1.5),
  },
  '& .MuiMenuItem-root.disableHover': {
    cursor: 'unset',
  },
  '& .MuiMenuItem-root.disableHover:hover': {
    backgroundColor: 'inherit',
  },
}));

export const UserMenu = () => {
  const [menuAnchorEl, setMenuAnchorElUser] = useState<null | HTMLElement>(
    null,
  );
  const { openedPanel, setOpenedPanel } = useContext(UIStateContext);
  const { oktaAuth, authState } = useOktaAuth();

  const username = authState?.idToken?.claims.name ?? '';
  const email = authState?.idToken?.claims.email ?? '';
  const os = getOS();
  const theKey = getKey(os);

  const userInitials = useCallback(() => {
    const [[first], [second]] = username.split(' ');
    return first + second;
  }, [username]);

  const onAvatarClick = (event: MouseEvent<HTMLElement>) =>
    setMenuAnchorElUser(event.currentTarget);

  const closePanel = () => setOpenedPanel(null);
  const closeMenu = () => setMenuAnchorElUser(null);

  const handleMenuItemClick = (action: VoidFunction) => {
    action();
    closeMenu();
  };

  const logout = async () => oktaAuth.signOut();

  return (
    <>
      <Tooltip arrow title={DICTIONARY.COMMON.USER_AVATAR_TOOLTIP}>
        <IconButton
          aria-label={SETTING_BUTTON_LABEL}
          color='inherit'
          onClick={onAvatarClick}
        >
          <Avatar
            sx={{
              bgcolor: grey[400],
              color: (theme) => theme.palette.getContrastText(grey[400]),
            }}
          >
            {userInitials()}
          </Avatar>
        </IconButton>
      </Tooltip>
      <StyledMenu
        id='menu-appbar'
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={closeMenu}
      >
        <Box px={1.5} pt={1.5}>
          <Typography fontWeight={500}>{username}</Typography>
          <Typography>{email}</Typography>
        </Box>
        <PermissionsChecker name='FixturePage.HeaderPanel.Mode'>
          <MenuItem divider disableRipple className='disableHover'>
            <ThemeToggle />
          </MenuItem>
        </PermissionsChecker>

        <SuperAdminViewMenuItem onClick={handleMenuItemClick} />
        <PermissionsChecker name='PreferencesPage'>
          <MenuItem
            divider
            onClick={() =>
              handleMenuItemClick(() => setOpenedPanel(PANEL.PREFERENCES))
            }
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemTextStyled>
              {DICTIONARY.COMMON.PREFERENCES} <KeyboardButton theKey='p' />
            </ListItemTextStyled>
          </MenuItem>
        </PermissionsChecker>
        <MenuItem
          divider
          onClick={() =>
            handleMenuItemClick(() => setOpenedPanel(PANEL.KEYBOARD_SHORTCUTS))
          }
        >
          <ListItemIcon>
            <KeyboardIcon fontSize='small' />
          </ListItemIcon>

          <ListItemTextStyled>
            <Typography>{DICTIONARY.COMMON.SHORTCUTS}</Typography>
            <Typography display='flex'>
              <KeyboardButton theKey={theKey} />
              +
              <KeyboardButton theKey='/' />
            </Typography>
          </ListItemTextStyled>
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <PowerSettingsNew />
          </ListItemIcon>
          <ListItemText>{DICTIONARY.COMMON.LOGOUT}</ListItemText>
        </MenuItem>
      </StyledMenu>
      <PreferencesDialog
        open={openedPanel === PANEL.PREFERENCES}
        onClose={closePanel}
      />
      <ShortcutDialog
        open={openedPanel === PANEL.KEYBOARD_SHORTCUTS}
        onClose={closePanel}
      />
    </>
  );
};
