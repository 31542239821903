import { Button, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { convertSecondsToTimeWithoutHours } from '@/utils/convertSecondsToTime/convertSecondsToTime';
import { COMMON_STRING } from '@/constants/dictionary';

interface SnackbarSessionExpiredActionProps {
  timerDuration: number;
  extendAccessToken: () => Promise<void>;
  setShowTimer: Dispatch<SetStateAction<boolean>>;
  setIsExpiredDialogOpen: Dispatch<SetStateAction<boolean>>;
}
export const SnackbarSessionExpiredAction = ({
  timerDuration,
  extendAccessToken,
  setShowTimer,
  setIsExpiredDialogOpen,
}: SnackbarSessionExpiredActionProps) => {
  const [timeDuration, setTimeDuration] = useState(
    +(timerDuration / 1000).toFixed(),
  ); //time in sec
  const [countdownText, setCountdownText] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeDuration((prev) => {
        const newTimeDuration = prev - 1;

        if (newTimeDuration <= 0) {
          setShowTimer(false);
          setIsExpiredDialogOpen(true);
          clearInterval(intervalId);
        }

        return +newTimeDuration.toFixed();
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    setCountdownText(convertSecondsToTimeWithoutHours(timeDuration));
  }, [timeDuration]);

  useEffect(() => {
    if (timeDuration > 0) {
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeDuration]);

  return (
    <Stack flexDirection='row' alignItems='center' gap={2} pr={2}>
      <Typography variant='body2'>
        {COMMON_STRING.YOUR_SESSION_WILL_EXPIRE_IN}: {countdownText}
      </Typography>
      <Button variant='text' color='inherit' onClick={extendAccessToken}>
        {COMMON_STRING.EXTEND_SESSION}
      </Button>
    </Stack>
  );
};
