import { useEffect, useMemo, useState } from 'react';

export const TIME_LIMIT_IN_MILLISECONDS = 180000; // 3 minutes

interface UseSessionTimerProps {
  accessTokenExpireAt: number;
}

export const useSessionTimer = ({
  accessTokenExpireAt,
}: UseSessionTimerProps) => {
  const [showTimer, setShowTimer] = useState(false);

  const realTime = useMemo(
    () => new Date().getTime(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessTokenExpireAt],
  );

  const differenceBetweenPreExpireTokenAndRealTime = useMemo(() => {
    const differenceBetweenExpireTokenAndRealTime =
      accessTokenExpireAt - realTime;

    return differenceBetweenExpireTokenAndRealTime <= TIME_LIMIT_IN_MILLISECONDS
      ? 0
      : differenceBetweenExpireTokenAndRealTime - TIME_LIMIT_IN_MILLISECONDS;
  }, [accessTokenExpireAt, realTime]);

  const timerDuration = useMemo(
    () =>
      differenceBetweenPreExpireTokenAndRealTime === 0
        ? accessTokenExpireAt - realTime >= 0
          ? accessTokenExpireAt - realTime
          : 0
        : TIME_LIMIT_IN_MILLISECONDS,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [differenceBetweenPreExpireTokenAndRealTime],
  );

  useEffect(() => {
    setTimeout(() => {
      setShowTimer(true);
    }, differenceBetweenPreExpireTokenAndRealTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [differenceBetweenPreExpireTokenAndRealTime]);

  return { showTimer, timerDuration, setShowTimer };
};
