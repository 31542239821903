import { useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { konsole } from '@/utils/konsole';
import { FixturesFilter } from '@/components/FixturesTable/FixturesFilters/types';
import { FILTERS_HISTORY_MAX_LENGTH } from '@/components/ActionsTable/ActionFilters/constants';

export const SS_FIXTURES_HISTORY_KEY = 'MT-fixtures-filters-history';

export const useFixturesFilters = () => {
  const [filtersHistory, setFiltersHistory] = useSessionStorage<
    FixturesFilter[][]
  >(SS_FIXTURES_HISTORY_KEY, []);

  const [filtersList, setNewFiltersList] = useState<FixturesFilter[]>(
    filtersHistory.at(-1) ?? [],
  );

  const undoFilters = () => {
    if (!filtersHistory || !filtersHistory.length) return;
    const newHistory = [...filtersHistory];
    newHistory.pop();
    const prevFilters = newHistory.at(-1) || [];
    setNewFiltersList(prevFilters);
    setFiltersHistory(newHistory);
    konsole.groupCollapsed('Filters');
    konsole.table('New Filters', prevFilters);
    konsole.log('Filters history', newHistory);
    konsole.groupEnd();
  };

  const setFilters = (newFilters: FixturesFilter[]) => {
    const newHistory = [...filtersHistory, newFilters];
    newHistory.length > FILTERS_HISTORY_MAX_LENGTH && newHistory.shift();
    setFiltersHistory(newHistory);
    setNewFiltersList(newFilters);
    konsole.groupCollapsed('Filters');
    konsole.table('New Filters', newFilters);
    konsole.log('Filters history', newHistory);
    konsole.groupEnd();
  };

  const onFilterRemove = (filterIndex: number) => {
    if (!filtersList || !filtersList.length) return;
    const newFilters = [...filtersList];
    newFilters.splice(filterIndex, 1);
    setFilters(newFilters);
  };

  const clearFilters = () => {
    setFilters([]);
  };

  return {
    filtersList,
    filtersHistory,
    undoFilters,
    setFilters,
    onFilterRemove,
    clearFilters,
  };
};
