import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { COLLECTION_RANK, COLLECTION_RANK_NAMES } from '@/service/constants';
import { COMMON_STRING } from '@/constants/dictionary';

interface RankWithIconProps {
  rank: COLLECTION_RANK_NAMES | undefined;
}

export const rankIconTestId = 'rank-icon-test-id';

export const RankWithIcon = ({ rank }: RankWithIconProps) => {
  const { palette, spacing } = useTheme();

  if (!rank) {
    return (
      <Typography variant='body2'>{COMMON_STRING.NOT_AVAILABLE}</Typography>
    );
  }

  return (
    <Stack flexDirection='row' alignItems='center' gap={0.5}>
      <Box
        sx={{
          background: palette.rank[COLLECTION_RANK[rank]],
          borderRadius: spacing(0.5),
          width: spacing(1),
          height: spacing(3),
        }}
        data-testid={rankIconTestId}
      />
      <Typography variant='body2'>{rank}</Typography>
    </Stack>
  );
};
