import { Paper, Stack, Box, TableSortLabel } from '@mui/material';
import { useContext, MouseEvent } from 'react';
import { RolePermissionsContext } from '@/contexts/rolePermissions/context';
import { UIStateContext } from '@/contexts/UIState/context';
import { Order, SortBy } from '@/utils/sort/useSort';
import { TABLE_SORT_ORDER } from '@/constants/dictionary';
import { hasPermissionsToElement } from '../PermissionsChecker/utils';
import { getColumnHeader, generateColumns } from './utils';

interface TableHeaderProps {
  sortOrder?: Order;
  sortBy?: SortBy;
  onRequestSort?: (event: MouseEvent<unknown>, property: SortBy) => void;
}

export const ActionsTableHeader = ({
  sortBy,
  sortOrder,
  onRequestSort,
}: TableHeaderProps) => {
  const { permissions } = useContext(RolePermissionsContext);
  const { isSlaBreachActionSelected } = useContext(UIStateContext);

  const canAccessConfirmOrComment =
    hasPermissionsToElement('FixturePage.MatchData.ConfirmFlag', permissions) ||
    hasPermissionsToElement('FixturePage.MatchData.Comment', permissions);

  const createSortHandler =
    (property: SortBy) => (event: MouseEvent<unknown>) => {
      if (!onRequestSort) return;
      onRequestSort(event, property);
    };

  const columnsWithoutMore = Object.entries(
    getColumnHeader(isSlaBreachActionSelected).slice(0, -1),
  );

  return (
    <Paper
      elevation={2}
      sx={{
        top: 0,
        position: 'sticky',
        zIndex: 1,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <Stack
        direction='row'
        sx={{
          alignItems: 'center',
          '&>.MuiBox-root': {
            fontSize: '0.75rem',
            fontWeight: 700,
            lineHeight: 1.25,
            padding: (theme) => theme.spacing(0.5, 0, 0.5, 1),
            '&:first-of-type': {
              paddingLeft: (theme) => theme.spacing(2.5),
            },
            '&:last-of-type': {
              padding: (theme) => theme.spacing(0.5, 0, 0.5, 0),
            },
          },
        }}
      >
        {columnsWithoutMore.map(([key, value], index) => (
          <Box
            width={value.width}
            key={key}
            textAlign={
              isSlaBreachActionSelected &&
              index >= columnsWithoutMore.length - 4
                ? 'center'
                : 'left'
            }
          >
            {index < columnsWithoutMore.length - 4 ? (
              <TableSortLabel
                active={!!sortBy?.primary && sortBy.primary === value.sortAttr}
                disabled={!value.sortAttr}
                direction={
                  value.sortAttr && sortBy?.primary === value.sortAttr
                    ? sortOrder
                    : TABLE_SORT_ORDER.ASC
                }
                onClick={createSortHandler({
                  primary: value.sortAttr,
                  secondary: value.secondarySortAttr,
                })}
              >
                {value.name}
              </TableSortLabel>
            ) : (
              value.name
            )}
          </Box>
        ))}

        {canAccessConfirmOrComment && (
          <Box width={generateColumns().MORE.width} textAlign='right'></Box>
        )}
      </Stack>
    </Paper>
  );
};
