import {
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';
import { ChangeEvent } from 'react';

interface SearchFieldProps {
  id?: string;
  searchValue: string;
  placeholder: string;
  onValueChange: (value: string) => void;
  sx?: SxProps<Theme>;
}

export const SearchField = ({
  id,
  searchValue,
  placeholder,
  onValueChange,
  sx,
}: SearchFieldProps) => {
  return (
    <TextField
      id={id}
      sx={sx}
      value={searchValue}
      placeholder={placeholder}
      size='small'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start' sx={{ mt: 0.1 }}>
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              onClick={() =>
                searchValue !== '' ? onValueChange('') : undefined
              }
              edge='end'
              sx={{ visibility: searchValue ? 'initial' : 'hidden' }}
            >
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        onValueChange(e.target.value);
      }}
    />
  );
};
