import { FC, forwardRef, MouseEvent, ReactElement } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

export const checkboxMenuItemTestId = 'checkbox-menu-item-id';
export const checkboxMenuItemIconTestId = 'checkbox-menu-icon-id';
export const checkboxMenuItemTextTestId = 'checkbox-menu-text-id';

export type CheckboxMenuItemProps = {
  onClick?: (e?: MouseEvent<HTMLLIElement>) => void;
  icon?: ReactElement;
  text: string;
  showIcon?: boolean;
  disabled?: boolean;
  iconItemMinWidth?: string;
};

export const CheckboxMenuItem: FC<CheckboxMenuItemProps> = forwardRef<
  HTMLLIElement,
  CheckboxMenuItemProps
>(
  (
    {
      onClick,
      icon,
      text,
      showIcon = false,
      disabled = false,
      iconItemMinWidth = '36px',
    },
    ref,
  ) => {
    return (
      <MenuItem
        onClick={onClick}
        ref={ref}
        data-testid={checkboxMenuItemTestId}
        disabled={disabled}
        sx={{ '.MuiListItemIcon-root': { minWidth: iconItemMinWidth } }}
      >
        {(showIcon || icon) && (
          <ListItemIcon data-testid={checkboxMenuItemIconTestId}>
            {icon}
          </ListItemIcon>
        )}
        <ListItemText data-testid={checkboxMenuItemTextTestId}>
          {text}
        </ListItemText>
      </MenuItem>
    );
  },
);
