import { COMMON_STRING } from '@/constants/dictionary';
import { RadioLabelsType } from './types';

export const FILTERS_HISTORY_MAX_LENGTH = 10;

export const FIXTURES_FILTER_PROPERTY = {
  COMPETITION_ID: 'competitionId',
  SPORT_ID: 'sportId',
  TELEGRAM_ADMIN_ID: 'telegramAdminId',
  SUPERVISOR_ID: 'supervisorId',
  WORKSTATION_ID: 'workstationId',
  RANK: 'rank',
} as const;

export const NON_PROPERTY_FILTER = {
  HIDE_UPDATED_ACTIONS: 'hideUpdatedActions',
  LATENCY: 'latency',
  MISTAKE: 'mistake',
} as const;

export const FIXTURES_FILTER_DISPLAY_NAME = {
  COMPETITION_ID: 'Competition',
  SPORT_ID: 'Sport',
  TELEGRAM_ADMIN_ID: 'Telegram Admin',
  SUPERVISOR_ID: 'Supervisor',
  WORKSTATION_ID: 'Workstation',
  RANK: 'Rank',
};

export const FILTER_DISPLAY_VALUE = {
  COMMENT: {
    WITH_COMMENT: 'With Comment',
    WITH_OUT_COMMENT: 'Without Comment',
  },
  FLAG: { FLAGGED: 'Flagged', NOT_FLAGGED: 'Not Flagged' },
  LATENCY: {
    WITH_LATENCY: 'With Latency',
    WITH_OUT_LATENCY: 'Without Latency',
  },
  MISTAKE: {
    WITH_MISTAKE: 'With Mistakes',
    WITH_OUT_MISTAKE: 'Without Mistakes',
  },
};

export type FixturesFilterProperty =
  (typeof FIXTURES_FILTER_PROPERTY)[keyof typeof FIXTURES_FILTER_PROPERTY];

export const GENERIC_FILTER_VALUE = {
  UNSET: '',
  FALSY: 'falsy',
  TRUTHY: 'truthy',
  UNKNOWN: 'unknown',
} as const;
export type GenericFilterValue =
  (typeof GENERIC_FILTER_VALUE)[keyof typeof GENERIC_FILTER_VALUE];

export const FILER_TEMPLATE_COLUMN_QUANTITY = 3;

export const RADIO_FLAG_LABELS: RadioLabelsType = {
  unset: COMMON_STRING.ALL,
  falsy: FILTER_DISPLAY_VALUE.FLAG.NOT_FLAGGED,
  truthy: FILTER_DISPLAY_VALUE.FLAG.FLAGGED,
};

export const RADIO_COMMENT_LABELS: RadioLabelsType = {
  unset: COMMON_STRING.ALL,
  falsy: FILTER_DISPLAY_VALUE.COMMENT.WITH_OUT_COMMENT,
  truthy: FILTER_DISPLAY_VALUE.COMMENT.WITH_COMMENT,
};

export const RADIO_PLAYER_LABELS: RadioLabelsType = {
  unset: COMMON_STRING.ALL,
  falsy: COMMON_STRING.NONE,
  unknown: COMMON_STRING.UNKNOWN,
};

export const RADIO_ACTIONS_UPDATED: RadioLabelsType = {
  falsy: COMMON_STRING.ALL,
  truthy: COMMON_STRING.ONLY_NOT_UPDATED,
};

export const RADIO_SLA_LATENCY: RadioLabelsType = {
  unset: COMMON_STRING.ALL,
  truthy: FILTER_DISPLAY_VALUE.LATENCY.WITH_LATENCY,
  falsy: FILTER_DISPLAY_VALUE.LATENCY.WITH_OUT_LATENCY,
};

export const RADIO_SLA_MISTAKE: RadioLabelsType = {
  unset: COMMON_STRING.ALL,
  truthy: FILTER_DISPLAY_VALUE.MISTAKE.WITH_MISTAKE,
  falsy: FILTER_DISPLAY_VALUE.MISTAKE.WITH_OUT_MISTAKE,
};
