import { useContext, useEffect, useMemo, useState } from 'react';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  filterAdvancedTableData,
  generateAdvancedStatsAllSportsHeader,
  generateAdvancedStatsBasketballHeader,
} from '@/components/AdvancedStatsTable/utils';
import {
  AdvancedStatsData,
  AdvancedStatsTableHeader,
} from '@/components/AdvancedStatsTable/types';
import { StatsContext } from '@/contexts/stats/context';
import { InfoMessage } from '@/components/common/InfoMessage';
import { getSport } from '@/service/utils/getSport';
import { SPORT_ID } from '@/service/constants';
import { COMMON_STRING } from '@/constants/dictionary';
import { AdvancedStatsBasketballTable } from './Tables/AdvancedStatsBasketballTable';
import { AdvancedStatsAllSportsTable } from './Tables/AdvancedStatsAllSportsTable';

export const AdvancedStatsTable = () => {
  const {
    state: { fixtureSummary },
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);
  const {
    activeButtons: { quarter, type, team },
  } = useContext(StatsContext);
  const [newTableHeader, setNewTableHeader] =
    useState<AdvancedStatsTableHeader>();

  const advancedStatsData: AdvancedStatsData[] | undefined = useMemo(() => {
    return filterAdvancedTableData({
      activeQuarter: quarter,
      activeType: type,
      activeTeam: team,
      actions: fixtureSummary?.teamActionAggregates,
      homeTeamId: fixtureSummary?.homeTeam.id,
    });
  }, [fixtureSummary, quarter, type, team]);

  const isBasketball = getSport(fixtureConfig)?.id === SPORT_ID.BASKETBALL;

  useEffect(() => {
    if (advancedStatsData) {
      isBasketball
        ? setNewTableHeader(
            generateAdvancedStatsBasketballHeader(type, fixtureConfig),
          )
        : setNewTableHeader(
            generateAdvancedStatsAllSportsHeader(advancedStatsData),
          );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancedStatsData, isBasketball, type]);

  if (!advancedStatsData || advancedStatsData.length === 0) {
    return (
      <InfoMessage message={COMMON_STRING.ADVANCED_STATS_DOES_NOT_EXIST} />
    );
  }
  if (isBasketball) {
    return (
      <AdvancedStatsBasketballTable
        data={advancedStatsData}
        headerData={newTableHeader}
      />
    );
  }
  return (
    <AdvancedStatsAllSportsTable
      data={advancedStatsData}
      headerData={newTableHeader}
    />
  );
};
