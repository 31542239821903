import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FixtureSummary } from '@/service/types';
import { COMMON_STRING } from '@/constants/dictionary';
import { convertSecondsToTimeWithoutHours } from '@/utils/convertSecondsToTime/convertSecondsToTime';

interface SnackbarStartMatchActionProps {
  fixtureSummary: FixtureSummary | null;
  timerDuration: number;
}
export const SnackbarStartMatchAction = ({
  fixtureSummary,
  timerDuration,
}: SnackbarStartMatchActionProps) => {
  const [timeDuration, setTimeDuration] = useState(
    +(timerDuration / 1000).toFixed(),
  ); //time in sec
  const [countdownText, setCountdownText] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeDuration((prev) => {
        const newTimeDuration = prev - 1;

        if (newTimeDuration <= 0) {
          clearInterval(intervalId);
        }

        return +newTimeDuration.toFixed();
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    setCountdownText(convertSecondsToTimeWithoutHours(timeDuration));
  }, [timeDuration]);

  useEffect(() => {
    if (timeDuration > 0) {
      return;
    }

    if (fixtureSummary?.currentPeriod !== fixtureSummary?.periods.length) {
      setCountdownText(COMMON_STRING.SOON);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixtureSummary?.periods, fixtureSummary?.currentPeriod, timeDuration]);

  return <Typography variant='body2'>{countdownText}</Typography>;
};
