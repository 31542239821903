import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
import { useContext, useMemo } from 'react';
import { FIXTURE_TABLE_HEADCELLS } from '@/components/FixturesTable/constants';
import { FixturesTableContext } from '@/components/FixturesTable/context/FixturesTableContext';

export const FixturesTableHead = () => {
  const {
    data: { fixturesWithIncidents },
    checkboxes: { selectedFixtures, handleSelectAllFixtures },
  } = useContext(FixturesTableContext);

  const selectedFixturesCount = useMemo(
    () => selectedFixtures.length,
    [selectedFixtures],
  );

  const fixturesWithIncidentsCount = useMemo(
    () => fixturesWithIncidents?.collections.data.length || 0,
    [fixturesWithIncidents?.collections.data],
  );

  return (
    <TableHead
      sx={{ backgroundColor: (theme) => theme.palette.background.default }}
    >
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={
              !!selectedFixturesCount &&
              selectedFixturesCount < fixturesWithIncidentsCount
            }
            checked={
              !!fixturesWithIncidentsCount &&
              selectedFixturesCount === fixturesWithIncidentsCount
            }
            onChange={handleSelectAllFixtures}
          />
        </TableCell>
        <TableCell />
        {FIXTURE_TABLE_HEADCELLS.map(({ id, name }) => (
          <TableCell
            key={id}
            align={typeof id === 'string' ? 'left' : 'center'}
            sx={{ fontSize: '.75rem', fontWeight: 700, lineHeight: 1.25 }}
          >
            {typeof id === 'string'
              ? name
              : `${name} (${fixturesWithIncidents?.summary.find(
                  (issue) => issue.issueId === id,
                )?.totalIssuesCount})`}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};
