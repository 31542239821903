import { Stack, Radio, FormControlLabel } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { PreferencesContext } from '@/contexts/preferences/context';
import { FixtureConfigSport } from '@/service/types';
import { COMMON_STRING } from '@/constants/dictionary';
import { SPORT_NAME } from '@/service/constants';
import { TemplateDragDirection, TemplateFormData } from './types';
import { SortableTemplateList } from './SortableTemplateList/SortableTemplateList';

type DisplayTemplateAccordionsProps = {
  templates: TemplateFormData[];
  selectedTemplateId: string | undefined;
  sport: FixtureConfigSport | undefined;
  onEdit: (id: string, sportId: number) => void;
  onDelete: (id: string, sportId: number) => void;
  editFromDrawer?: boolean;
};

export const DisplayTemplateAccordions: FC<DisplayTemplateAccordionsProps> = ({
  templates,
  selectedTemplateId,
  onEdit,
  onDelete,
  editFromDrawer,
  sport,
}) => {
  const {
    actions: { setSelectedTemplate },
  } = useContext(PreferencesContext);

  const [accordionOpen, setAccordionOpen] = useState<string | undefined>(
    selectedTemplateId,
  );
  const templateForCurrentSport = templates.find(
    (template) => template.id === selectedTemplateId,
  );

  const handleNoneClick = async (
    event: React.ChangeEvent<HTMLInputElement>,
    selected: boolean,
  ) => {
    await setSelectedTemplate(undefined, templateForCurrentSport?.sportId);
    enqueueSnackbar(
      `No default template selected for ${
        SPORT_NAME[templateForCurrentSport?.sportId as keyof typeof SPORT_NAME]
      }`,
      {
        variant: 'info',
      },
    );
    setAccordionOpen(undefined);
  };

  return (
    <Stack justifyContent={'flex-start'} sx={{ width: '100%' }}>
      <FormControlLabel
        label={COMMON_STRING.NO_TEMPLATE}
        control={
          <Radio
            checked={templateForCurrentSport === undefined}
            onChange={handleNoneClick}
            value={undefined}
          />
        }
        sx={{ mx: 0.6, my: 1 }}
      />
      <SortableTemplateList
        dragDirection={TemplateDragDirection.Vertical}
        templates={templates}
        accordionProps={{
          setAccordionOpen: setAccordionOpen,
          accordionOpen: accordionOpen,
          onEdit: onEdit,
          onDelete: onDelete,
          editFromDrawer: editFromDrawer,
        }}
        selectedTemplateId={selectedTemplateId}
      />
    </Stack>
  );
};
