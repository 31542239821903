import { Box, Typography, useTheme } from '@mui/material';
import { THEME_MODE } from '@/contexts/theme/constants';
import { FixturesTable } from '@/components/FixturesTable/FixturesTable';
import { COMMON_STRING } from '@/constants/dictionary';
import { FixturesTableProvider } from '@/components/FixturesTable/context/FixturesTableProvider';
import { PermissionsChecker } from '@/components/PermissionsChecker/PermissionsChecker';

export const SuperAdmin = () => {
  const {
    palette: {
      common: { black, white },
      mode,
    },
    spacing,
  } = useTheme();

  return (
    <PermissionsChecker name='SuperAdminPage' withMessage>
      <Box width='100%' p={spacing(1, 3, 0.5, 3)}>
        <Typography
          variant='h6'
          color={mode === THEME_MODE.LIGHT ? black : white}
        >
          {COMMON_STRING.FIXTURES_WITH_INCIDENTS}
        </Typography>
      </Box>
      <FixturesTableProvider>
        <FixturesTable />
      </FixturesTableProvider>
    </PermissionsChecker>
  );
};
