import { Dispatch, SetStateAction, useContext } from 'react';
import { Button, Stack, useTheme } from '@mui/material';
import { FixtureScorerRating } from '@/service/types';
import { API_METHOD } from '@/service/constants';
import { useScoringRate } from '@/service/hooks/useScoringRate';
import {
  calculateOverallScore,
  compareRatings,
} from '@/components/ComparisonRating/utils';
import { ScoringContext } from '@/contexts/scoring/context';
import { COMMON_STRING, SUCCESS_STRING } from '@/constants/dictionary';
import {
  AddScorerRatingMsg,
  RemoveScorerRatingMsg,
  SCORING_WORKER_HOST_ACTION,
  UpdateScorerRatingMsg,
} from '@/workers/scoring/types';

interface ComparisonRatingButtonsProps {
  oktaId: string | null;
  userId: string | undefined;
  rating: FixtureScorerRating | undefined;
  newRating: FixtureScorerRating | undefined;
  setNewRating: Dispatch<SetStateAction<FixtureScorerRating | undefined>>;
}

export const ComparisonRatingButtons = ({
  oktaId,
  userId,
  rating,
  newRating,
  setNewRating,
}: ComparisonRatingButtonsProps) => {
  const theme = useTheme();
  const {
    state: { fixtureId, fixtureSummary },
    useDispatchWithResponse,
  } = useContext(ScoringContext);
  const { mutate } = useScoringRate({ fixtureId });

  const hasChanges = !!(rating && newRating)
    ? compareRatings(rating, newRating)
    : false;

  const addScorerRating = useDispatchWithResponse<AddScorerRatingMsg>(
    SCORING_WORKER_HOST_ACTION.ADD_SCORER_RATING,
  );

  const updateScorerRating = useDispatchWithResponse<UpdateScorerRatingMsg>(
    SCORING_WORKER_HOST_ACTION.UPDATE_SCORER_RATING,
  );

  const removeScorerRating = useDispatchWithResponse<RemoveScorerRatingMsg>(
    SCORING_WORKER_HOST_ACTION.REMOVE_SCORER_RATING,
  );

  const submitHandler = async (
    action: API_METHOD.POST | API_METHOD.PUT | API_METHOD.DELETE,
  ) => {
    const defaultRequestData = {
      fixtureId,
      collectionId: fixtureSummary?.collectionId,
      requestTime: new Date().toISOString(),
      oktaId,
      userId,
    };

    if (action === API_METHOD.DELETE) {
      await removeScorerRating.dispatch(defaultRequestData, {
        successMessage: SUCCESS_STRING.RATING_SUCCESSFULLY_REMOVED,
      });
    } else {
      const requestData = {
        ...defaultRequestData,
        quantityScore: newRating?.quantityScore,
        latencyScore: newRating?.latencyScore,
        comment: newRating?.comment,
      };

      if (action === API_METHOD.POST) {
        await addScorerRating.dispatch(requestData, {
          successMessage: SUCCESS_STRING.RATING_SUCCESSFULLY_RATED,
        });
      } else if (action === API_METHOD.PUT) {
        await updateScorerRating.dispatch(requestData, {
          successMessage: SUCCESS_STRING.RATING_SUCCESSFULLY_SAVED,
        });
      }
    }

    return await mutate();
  };

  return (
    <Stack
      padding={theme.spacing(2, 3)}
      flexDirection='row'
      justifyContent={!!rating ? 'space-between' : 'end'}
    >
      {!!(rating && newRating) && (
        <Button
          aria-hidden={!newRating}
          variant='text'
          onClick={() => submitHandler(API_METHOD.DELETE)}
        >
          {COMMON_STRING.REMOVE}
        </Button>
      )}

      <Stack flexDirection='row' gap={2}>
        <Button
          variant='contained'
          disabled={hasChanges}
          onClick={() =>
            !!(!rating && newRating)
              ? submitHandler(API_METHOD.POST)
              : !!(rating && newRating)
              ? submitHandler(API_METHOD.PUT)
              : null
          }
        >
          {!!rating ? COMMON_STRING.SAVE : COMMON_STRING.ADD}
        </Button>
        <Button
          variant='outlined'
          disabled={hasChanges}
          onClick={() =>
            !!rating
              ? setNewRating(calculateOverallScore(rating))
              : setNewRating(undefined)
          }
        >
          {COMMON_STRING.CANCEL}
        </Button>
      </Stack>
    </Stack>
  );
};
