import { ChangeEvent, createContext, Dispatch, SetStateAction } from 'react';
import {
  Collection,
  FixturesWithIncidents,
} from '@/contexts/fixturesWithIncidents/types';
import { FixturesFilter } from '@/components/FixturesTable/FixturesFilters/types';

export type FixturesTableContextType = {
  data: {
    fixturesWithIncidents: FixturesWithIncidents | undefined;
    isLoading: boolean;
  };

  refresh: {
    refreshInterval: number;
    setRefreshInterval: Dispatch<SetStateAction<number>>;
  };
  pagination: {
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    rowsPerPage: number;
    setRowsPerPage: Dispatch<SetStateAction<number>>;
  };
  checkboxes: {
    handleSelectAllFixtures: (event: ChangeEvent<HTMLInputElement>) => void;
    handleSelectFixture: (event: React.MouseEvent<unknown>, id: string) => void;
    selectedFixtures: Collection['collectionId'][];
    handleDeselectAllFixtures: VoidFunction;
  };
  actions: {
    assignMeHandler: (collectionIds: Collection['collectionId'][]) => void;
    unAssignMeHandler: (collectionIds: Collection['collectionId'][]) => void;
  };
  filters: {
    ftSearch: string;
    setFtSearch: Dispatch<SetStateAction<string>>;
    filtersList: FixturesFilter[];
    filtersHistory: FixturesFilter[][];
    undoFilters: () => void;
    setFilters: (newFilters: FixturesFilter[]) => void;
    onFilterRemove: (filterIndex: number) => void;
    clearFilters: () => void;
  };
};

export const FixturesTableContext = createContext<FixturesTableContextType>({
  data: {
    fixturesWithIncidents: undefined,
    isLoading: false,
  },
  refresh: {
    refreshInterval: 10000,
    setRefreshInterval: () => {},
  },
  pagination: {
    page: 0,
    setPage: () => {},
    rowsPerPage: 5,
    setRowsPerPage: () => {},
  },
  checkboxes: {
    handleSelectAllFixtures: () => {},
    handleSelectFixture: () => {},
    selectedFixtures: [],
    handleDeselectAllFixtures: () => {},
  },
  actions: {
    assignMeHandler: () => {},
    unAssignMeHandler: () => {},
  },
  filters: {
    ftSearch: '',
    setFtSearch: () => {},
    filtersList: [],
    filtersHistory: [],
    undoFilters: () => {},
    setFilters: () => {},
    onFilterRemove: () => {},
    clearFilters: () => {},
  },
});
