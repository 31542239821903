import { Button } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { COMMON_STRING } from '@/constants/dictionary';

interface RefreshIntervalButtonProps {
  actualRefreshInterval: number;
  refreshInterval: number;
  setRefreshInterval: Dispatch<SetStateAction<number>>;
}

export const RefreshIntervalButton = ({
  actualRefreshInterval,
  refreshInterval,
  setRefreshInterval,
}: RefreshIntervalButtonProps) => {
  return (
    <Button
      sx={{
        backgroundColor:
          refreshInterval === actualRefreshInterval
            ? 'rgba(255, 77, 0, 0.14)'
            : 'none',
      }}
      onClick={() => setRefreshInterval(refreshInterval)}
    >
      {!refreshInterval ? COMMON_STRING.NONE : `${refreshInterval / 1000}s`}
    </Button>
  );
};
