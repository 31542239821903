import { Stack, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CSSProperties } from 'react';
import { MatchSummaryTeam, TeamTimeoutsType } from '@/service/types';
import { FixtureScoreTimeoutColorBox } from '@/components/FixtureScore/FixtureScoreStyledComponents';

export const TIMEOUT_COLOR_BOX_LABEL = 'timeout-color-box';

interface FixtureTimeoutsProps {
  totalTimeouts: TeamTimeoutsType;
  teamTimeoutsLeft: TeamTimeoutsType;
  teamColor: MatchSummaryTeam['teamColor'];
  homeTeam?: boolean;
  ariaLabel?: string;
}

export const FixtureTimeouts = ({
  totalTimeouts,
  teamTimeoutsLeft,
  teamColor,
  homeTeam,
  ariaLabel,
}: FixtureTimeoutsProps) => {
  const theme = useTheme();
  return (
    <Stack
      flexDirection='row'
      justifyContent={homeTeam ? 'end' : 'start'}
      gap={0.75}
      pr={homeTeam ? 3.75 : 0}
      pl={!homeTeam ? 3.75 : 0}
      alignItems='center'
      aria-label={ariaLabel}
    >
      <Typography variant='subtitle2' fontSize={'0.75rem'}>
        Timeouts
      </Typography>
      {Array.from(
        {
          length: totalTimeouts,
        },
        (_, i) => i + 1,
      ).map((timeout, index) => (
        <FixtureScoreTimeoutColorBox
          key={index}
          style={
            {
              '--teamColor':
                timeout <= teamTimeoutsLeft
                  ? teamColor && `rgb(${teamColor})`
                  : theme.palette.timeoutColor,
            } as CSSProperties
          }
          aria-label={TIMEOUT_COLOR_BOX_LABEL}
        />
      ))}
    </Stack>
  );
};
