import { useContext, useEffect } from 'react';
import { ScoringContext } from '@/contexts/scoring/context';
import { Lineups } from '@/components/Lineups/Lineups';
import {
  LineupsActionsBox,
  LineupsTabBox,
  LineupsBox,
} from '@/components/FixtureTabs/FixtureTabsStyledComponents';
import { FIXTURE_ACTION_TYPE } from '@/service/constants';
import { COMMON_STRING } from '@/constants/dictionary';
import { ActionFilter } from '../ActionsTable/ActionFilters/useActionsFilters';
import { FILTER_PROPERTY } from '../ActionsTable/ActionFilters/constants';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';
import { ActionsTableProvider } from '../ActionsTable/context/ActionsTableProvider';
import { ActionsTable } from '../ActionsTable/ActionsTable';
import { getActionName } from './helpers/getActionName';

const SUSTITUTION_IN_FILTER: ActionFilter = {
  property: FILTER_PROPERTY.FIXTURE_ACTION_TYPE_ID,
  value: FIXTURE_ACTION_TYPE.SUBSTITUTION_IN,
  displayName: COMMON_STRING.ACTION,
  displayValue: 'Substitution in', // hardcoded value - might be different from config value
  isFromTemplate: true,
};

const SUSTITUTION_OUT_FILTER: ActionFilter = {
  property: FILTER_PROPERTY.FIXTURE_ACTION_TYPE_ID,
  value: FIXTURE_ACTION_TYPE.SUBSTITUTION_OUT,
  displayName: COMMON_STRING.ACTION,
  displayValue: 'Substitution out', // hardcoded value - might be different from config value
  isFromTemplate: true,
};

const LINEUPS_DEFAULT_FILTERS: ActionFilter[] = [
  SUSTITUTION_IN_FILTER,
  SUSTITUTION_OUT_FILTER,
];
let filterNamesInSync = false;

export const FixtureLineups = () => {
  const {
    state: { fixtureActions },
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);

  /**
   * This effect is used to synchronize filter display names
   * with actual fixture config action name
   */
  useEffect(() => {
    if (filterNamesInSync) return;
    const subInName = getActionName(
      SUSTITUTION_IN_FILTER.value as number,
      fixtureConfig,
    );
    if (SUSTITUTION_IN_FILTER.displayValue !== subInName) {
      SUSTITUTION_IN_FILTER.displayValue = subInName;
    }

    const subOutName = getActionName(
      SUSTITUTION_OUT_FILTER.value as number,
      fixtureConfig,
    );
    if (SUSTITUTION_OUT_FILTER.displayValue !== subOutName) {
      SUSTITUTION_OUT_FILTER.displayValue = subOutName;
    }
    filterNamesInSync = true;
  }, [fixtureConfig]);

  return (
    <LineupsTabBox sx={{ flexDirection: { xl: 'row' } }}>
      <LineupsBox>
        <Lineups />
      </LineupsBox>

      <PermissionsChecker name='FixturePage.Lineups.FixtureActions'>
        <LineupsActionsBox>
          <ActionsTableProvider
            actions={fixtureActions?.actions}
            initialFilters={LINEUPS_DEFAULT_FILTERS}
            excludeTemplates={true}
          >
            <ActionsTable disableFilters />
          </ActionsTableProvider>
        </LineupsActionsBox>
      </PermissionsChecker>
    </LineupsTabBox>
  );
};
