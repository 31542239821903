import { Autocomplete, Checkbox, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { FixturesFilter } from '@/components/FixturesTable/FixturesFilters/types';

export interface FixturesAutocompleteFilterProps {
  options: FixturesFilter[];
  onChange: (event: any, value: FixturesFilter[]) => void;
  label: string;
  disabled?: boolean;
  selectedOptions: FixturesFilter[];
}

export const FixturesAutocompleteFilter: FC<
  FixturesAutocompleteFilterProps
> = ({ options, onChange, label, disabled = false, selectedOptions }) => {
  const handleChange = (_: any, newValues: FixturesFilter[]) => {
    onChange(_, newValues);
  };

  return (
    <Stack direction='row' gap={1}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        disabled={disabled}
        size='small'
        componentsProps={{
          paper: { elevation: 10 },
        }}
        sx={{ flex: 1 }}
        value={selectedOptions}
        onChange={handleChange}
        options={options}
        isOptionEqualToValue={(opt, val) =>
          opt.property === val.property && opt.value === val.value
        }
        getOptionLabel={(option) =>
          option.displayValue || (option.value as string)
        }
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props}>
              <Checkbox
                size='small'
                icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                checkedIcon={<CheckBoxIcon fontSize='small' />}
                checked={selected}
              />
              {option.displayValue}
            </li>
          );
        }}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </Stack>
  );
};
