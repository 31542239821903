import useSWR from 'swr';
import { useSnackbar } from 'notistack';
import { FixtureComparisonRating } from '@/service/types';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import {
  fetchFromMTService,
  SERVICE_ENDPOINT,
} from '../fetcher/monitoringToolService';
import { ApiError } from '../ApiError';
import { HTTP_STATUS_CODE } from '../constants';

interface UseScoringRateParams {
  fixtureId: string;
}

export const useScoringRate = ({ fixtureId }: UseScoringRateParams) => {
  const token = useAuthToken();

  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate } = useSWR<FixtureComparisonRating[], ApiError>(
    token && fixtureId
      ? {
          token,
          endpoint: SERVICE_ENDPOINT.ScoringRate(fixtureId).scoringRate.GET,
        }
      : null,
    fetchFromMTService,
    {
      onErrorRetry: (e, _, config, revalidate, { retryCount }) => {
        if (
          e.status !== HTTP_STATUS_CODE.NOT_FOUND &&
          e.status !== HTTP_STATUS_CODE.UNAUTHORIZED
        ) {
          return enqueueSnackbar(e.title, { variant: 'error' });
        }
        if (e.status === HTTP_STATUS_CODE.NOT_FOUND) return;
        if (e.status === HTTP_STATUS_CODE.UNAUTHORIZED) return;
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
      revalidateOnFocus: false,
    },
  );

  return {
    comparisonRating: data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};
