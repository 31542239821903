import { FC, useEffect, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Divider, darken, lighten, Button } from '@mui/material';
import { BaseMTMenuProps } from '@/components/MTMenu/types';
import { MTMenu } from '@/components/MTMenu/MTMenu';
import { CheckboxDeselectAllItem } from '@/components/FixturesTable/CheckboxActionMenu/CheckboxMenuItems/CheckboxDeselectAllItem';
import { Collection } from '@/contexts/fixturesWithIncidents/types';
import { COMMON_STRING } from '@/constants/dictionary';

const selectedCheckboxButtonTestId = 'selected-button-test-id';

type CheckboxActionMenuProps<Model = any> = BaseMTMenuProps<Model> & {
  minSelectedItems?: number;
  selectedItems?: Collection['collectionId'][];
  selectHandler: VoidFunction;
};

export const CheckboxActionMenu: FC<CheckboxActionMenuProps> = ({
  children,
  minSelectedItems = 2,
  selectedItems,
  selectHandler,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedRowsCount = selectedItems?.length || 0;

  useEffect(() => {
    setIsOpen(false);
  }, [selectedItems]);

  if (minSelectedItems && selectedRowsCount < minSelectedItems) {
    return null;
  }

  if (selectedRowsCount === 0) {
    return null;
  }

  return (
    <MTMenu
      model={selectedItems}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      menuButton={
        <Button
          variant='outlined'
          size='small'
          disableElevation
          data-testid={selectedCheckboxButtonTestId}
          sx={{
            display: 'flex',
            gap: 1,
            whiteSpace: 'nowrap',
            minWidth: '10.5rem',
          }}
        >
          <Box component='span'>
            {COMMON_STRING.SELECTED} ({selectedRowsCount})
          </Box>
          <Divider
            orientation='vertical'
            sx={{
              borderColor: ({ palette }) =>
                palette.mode === 'dark'
                  ? darken(palette.primary.main, 0.2)
                  : lighten(palette.primary.main, 0.5),
            }}
          />
          <ArrowDropDownIcon sx={{ rotate: isOpen ? '180deg' : '0deg' }} />
        </Button>
      }
    >
      <>
        {children}
        <CheckboxDeselectAllItem deselectHandler={selectHandler} />
      </>
    </MTMenu>
  );
};
