import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { COMMON_STRING } from '@/constants/dictionary';
import { FixturesFilter } from '@/components/FixturesTable/FixturesFilters/types';
import { useSuperAdminFilters } from '@/service/hooks/useSuperAdminFilters';
import {
  FilterWithNumberId,
  FilterWithStringId,
} from '@/contexts/fixturesWithIncidents/types';
import { FILTER_ENDPOINTS_TYPE_KEYS } from '@/service/fetcher/monitoringToolService';
import {
  FIXTURES_FILTER_DISPLAY_NAME,
  FIXTURES_FILTER_PROPERTY,
} from '@/components/FixturesTable/FixturesFilters/constants';
import { FixturesAutocompleteFilter } from '@/components/FixturesTable/FixturesFilters/FixturesAutocompleteFilter';
import { isInFixturesFilters } from '@/components/FixturesTable/FixturesFilters/utils';

export interface FixturesFiltersFormProps {
  filters: FixturesFilter[];
  onApply: (newFilters: FixturesFilter[]) => void;
  onCancel: VoidFunction;
  drawerWidth: string;
}

export const FixturesFiltersForm: FC<FixturesFiltersFormProps> = ({
  filters,
  onApply,
  onCancel,
  drawerWidth,
}) => {
  const { data: competitions } = useSuperAdminFilters<FilterWithStringId>(
    FILTER_ENDPOINTS_TYPE_KEYS.competitions,
  );
  const { data: telegramAdmins } = useSuperAdminFilters<FilterWithStringId>(
    FILTER_ENDPOINTS_TYPE_KEYS.telegramAdmins,
  );
  const { data: supervisors } = useSuperAdminFilters<FilterWithStringId>(
    FILTER_ENDPOINTS_TYPE_KEYS.supervisors,
  );
  const { data: sports } = useSuperAdminFilters<FilterWithNumberId>(
    FILTER_ENDPOINTS_TYPE_KEYS.sports,
  );
  const { data: workstations } = useSuperAdminFilters<FilterWithNumberId>(
    FILTER_ENDPOINTS_TYPE_KEYS.workstations,
  );

  const { data: rankOptions } = useSuperAdminFilters<FilterWithNumberId>(
    FILTER_ENDPOINTS_TYPE_KEYS.ranks,
  );

  const [selectedCompetitions, setSelectedCompetitions] = useState<
    FixturesFilter[]
  >([]);
  const [selectedTelegramAdmins, setSelectedTelegramAdmins] = useState<
    FixturesFilter[]
  >([]);
  const [selectedSupervisors, setSelectedSupervisors] = useState<
    FixturesFilter[]
  >([]);
  const [selectedSports, setSelectedSports] = useState<FixturesFilter[]>([]);
  const [selectedWorkstations, setSelectedWorkstations] = useState<
    FixturesFilter[]
  >([]);
  const [ranks, setRanks] = useState<
    {
      id: string | null;
      name: string;
      checked: boolean;
    }[]
  >([]);

  const competitionOptions: FixturesFilter[] = useMemo(() => {
    if (!competitions) return [];
    return competitions.map(({ id, name }) => ({
      value: id,
      displayValue: name,
      property: FIXTURES_FILTER_PROPERTY.COMPETITION_ID,
      displayName: FIXTURES_FILTER_DISPLAY_NAME.COMPETITION_ID,
    }));
  }, [competitions]);

  const telegramAdminOptions: FixturesFilter[] = useMemo(() => {
    if (!telegramAdmins) return [];
    return telegramAdmins.map(({ id, name }) => ({
      value: id,
      displayValue: name,
      property: FIXTURES_FILTER_PROPERTY.TELEGRAM_ADMIN_ID,
      displayName: FIXTURES_FILTER_DISPLAY_NAME.TELEGRAM_ADMIN_ID,
    }));
  }, [telegramAdmins]);

  const supervisorOptions: FixturesFilter[] = useMemo(() => {
    if (!supervisors) return [];
    return supervisors.map(({ id, name }) => ({
      value: id,
      displayValue: name,
      property: FIXTURES_FILTER_PROPERTY.SUPERVISOR_ID,
      displayName: FIXTURES_FILTER_DISPLAY_NAME.SUPERVISOR_ID,
    }));
  }, [supervisors]);

  const sportOptions: FixturesFilter[] = useMemo(() => {
    if (!sports) return [];
    return sports.map(({ id, name }) => ({
      value: id,
      displayValue: name,
      property: FIXTURES_FILTER_PROPERTY.SPORT_ID,
      displayName: FIXTURES_FILTER_DISPLAY_NAME.SPORT_ID,
    }));
  }, [sports]);

  const workstationOptions: FixturesFilter[] = useMemo(() => {
    if (!workstations) return [];
    return workstations.map(({ id, name }) => ({
      value: id,
      displayValue: name,
      property: FIXTURES_FILTER_PROPERTY.WORKSTATION_ID,
      displayName: FIXTURES_FILTER_DISPLAY_NAME.WORKSTATION_ID,
    }));
  }, [workstations]);

  useEffect(() => {
    const newSelectedCompetitions: FixturesFilter[] = filters.filter(
      ({ property }) => property === FIXTURES_FILTER_PROPERTY.COMPETITION_ID,
    );

    setSelectedCompetitions(newSelectedCompetitions);

    const newSelectedTelegramAdmins: FixturesFilter[] = filters.filter(
      ({ property }) => property === FIXTURES_FILTER_PROPERTY.TELEGRAM_ADMIN_ID,
    );

    setSelectedTelegramAdmins(newSelectedTelegramAdmins);

    const newSelectedSupervisors: FixturesFilter[] = filters.filter(
      ({ property }) => property === FIXTURES_FILTER_PROPERTY.SUPERVISOR_ID,
    );

    setSelectedSupervisors(newSelectedSupervisors);

    const newSelectedSports: FixturesFilter[] = filters.filter(
      ({ property }) => property === FIXTURES_FILTER_PROPERTY.SPORT_ID,
    );

    setSelectedSports(newSelectedSports);

    const newSelectedWorkstations: FixturesFilter[] = filters.filter(
      ({ property }) => property === FIXTURES_FILTER_PROPERTY.WORKSTATION_ID,
    );

    setSelectedWorkstations(newSelectedWorkstations);
  }, [filters]);

  useEffect(() => {
    if (!rankOptions) return;

    const newRanks = rankOptions.map((rank) => ({
      id: rank.name,
      name: rank.name,
      checked: isInFixturesFilters(
        filters,
        FIXTURES_FILTER_PROPERTY.RANK,
        rank.name,
      ),
    }));

    setRanks(newRanks);
  }, [rankOptions, filters]);

  const onSelectedCompetitionsChange = (
    event: any,
    newValue: FixturesFilter[],
  ) => {
    setSelectedCompetitions(newValue);
  };

  const onRankChecked = (index: number, checked: boolean) => {
    const newRanks = [...ranks];
    newRanks[index].checked = checked;
    setRanks(newRanks);
  };

  const onSelectedTelegramAdminsChange = (
    event: any,
    newValue: FixturesFilter[],
  ) => {
    setSelectedTelegramAdmins(newValue);
  };

  const onSelectedSupervisorsChange = (
    event: any,
    newValue: FixturesFilter[],
  ) => {
    setSelectedSupervisors(newValue);
  };

  const onSelectedSportsChange = (event: any, newValue: FixturesFilter[]) => {
    setSelectedSports(newValue);
  };

  const onSelectedWorkstationsChange = (
    event: any,
    newValue: FixturesFilter[],
  ) => {
    setSelectedWorkstations(newValue);
  };

  const onConfirm = () => {
    const selectedCompetitionIdsFilter: FixturesFilter[] =
      !!selectedCompetitions.length ? selectedCompetitions : [];

    const selectedTelegramAdminIdsFilter: FixturesFilter[] =
      !!selectedTelegramAdmins.length ? selectedTelegramAdmins : [];

    const selectedSupervisorIdsFilter: FixturesFilter[] =
      !!selectedSupervisors.length ? selectedSupervisors : [];

    const selectedSportIdsFilter: FixturesFilter[] = !!selectedSports.length
      ? selectedSports
      : [];

    const selectedWorkstationIdsFilter: FixturesFilter[] =
      !!selectedWorkstations.length ? selectedWorkstations : [];

    const selectedRankFilters: FixturesFilter[] = ranks
      .filter(({ checked }) => checked)
      .map((rank) => ({
        displayValue: rank.name,
        displayName: FIXTURES_FILTER_DISPLAY_NAME.RANK,
        property: FIXTURES_FILTER_PROPERTY.RANK,
        value: rank.name,
      }));

    const composedFilters = [
      ...selectedCompetitionIdsFilter,
      ...selectedTelegramAdminIdsFilter,
      ...selectedSupervisorIdsFilter,
      ...selectedSportIdsFilter,
      ...selectedWorkstationIdsFilter,
      ...selectedRankFilters,
    ];

    onApply(composedFilters);
  };

  return (
    <Stack
      height='100%'
      alignItems='stretch'
      justifyContent='space-between'
      width={drawerWidth}
      maxWidth='90vw'
      divider={<Divider flexItem />}
    >
      <Stack
        py={2}
        gap={2}
        flex={1}
        overflow='auto'
        divider={<Divider flexItem />}
      >
        <Stack px={2} gap={1.5}>
          <Typography variant='subtitle2'>{COMMON_STRING.FILTER_BY}</Typography>

          <FixturesAutocompleteFilter
            options={sportOptions}
            onChange={onSelectedSportsChange}
            selectedOptions={selectedSports}
            label={FIXTURES_FILTER_DISPLAY_NAME.SPORT_ID}
          />

          <FixturesAutocompleteFilter
            options={telegramAdminOptions}
            onChange={onSelectedTelegramAdminsChange}
            selectedOptions={selectedTelegramAdmins}
            label={FIXTURES_FILTER_DISPLAY_NAME.TELEGRAM_ADMIN_ID}
          />

          <FixturesAutocompleteFilter
            options={supervisorOptions}
            onChange={onSelectedSupervisorsChange}
            selectedOptions={selectedSupervisors}
            label={FIXTURES_FILTER_DISPLAY_NAME.SUPERVISOR_ID}
          />

          <FixturesAutocompleteFilter
            options={workstationOptions}
            onChange={onSelectedWorkstationsChange}
            selectedOptions={selectedWorkstations}
            label={FIXTURES_FILTER_DISPLAY_NAME.WORKSTATION_ID}
          />
        </Stack>

        <Stack px={2}>
          <FixturesAutocompleteFilter
            options={competitionOptions}
            onChange={onSelectedCompetitionsChange}
            selectedOptions={selectedCompetitions}
            label={FIXTURES_FILTER_DISPLAY_NAME.COMPETITION_ID}
          />
        </Stack>

        {!!ranks.length && (
          <Stack px={2}>
            <Typography variant='subtitle2'>{COMMON_STRING.RANK}</Typography>
            <FormGroup>
              {ranks.map((rank, index) => (
                <FormControlLabel
                  key={rank.id || ''}
                  label={rank.name}
                  control={
                    <Checkbox
                      size='small'
                      onChange={(_, checked) => onRankChecked(index, checked)}
                      checked={rank.checked}
                    />
                  }
                />
              ))}
            </FormGroup>
          </Stack>
        )}
      </Stack>

      <Stack p={2} gap={1} direction='row' justifyContent='space-between'>
        <Button variant='text' onClick={onCancel}>
          {COMMON_STRING.CANCEL}
        </Button>
        <Button
          variant='contained'
          type='submit'
          onClick={onConfirm}
          sx={{ flex: 1 }}
        >
          {COMMON_STRING.APPLY}
        </Button>
      </Stack>
    </Stack>
  );
};
