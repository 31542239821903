import { useSnackbar } from 'notistack';
import useSWR from 'swr';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { ApiError } from '../ApiError';
import { HTTP_STATUS_CODE } from '../constants';
import {
  SERVICE_ENDPOINT,
  fetchFromMTService,
  FILTER_ENDPOINTS_TYPE_KEYS,
} from '../fetcher/monitoringToolService';

export const useSuperAdminFilters = <T>(
  filterType: keyof typeof FILTER_ENDPOINTS_TYPE_KEYS,
) => {
  const { enqueueSnackbar } = useSnackbar();
  const token = useAuthToken();

  const { data, error, mutate } = useSWR<T[], ApiError>(
    token
      ? {
          token,
          endpoint: SERVICE_ENDPOINT.SuperAdminFilters()[filterType].GET,
        }
      : null,
    fetchFromMTService,
    {
      onErrorRetry: (e, _, config, revalidate, { retryCount }) => {
        if (
          e.status !== HTTP_STATUS_CODE.NOT_FOUND &&
          e.status !== HTTP_STATUS_CODE.UNAUTHORIZED
        ) {
          return enqueueSnackbar(e.title, { variant: 'error' });
        }
        if (e.status === HTTP_STATUS_CODE.NOT_FOUND) return;
        if (e.status === HTTP_STATUS_CODE.UNAUTHORIZED) return;
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
      revalidateOnFocus: false,
    },
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};
