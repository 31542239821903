import MenuItem from '@mui/material/MenuItem';
import { ListItemIcon } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeIcon from '@mui/icons-material/Home';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useMemo } from 'react';
import { DICTIONARY } from '@/constants/dictionary';
import { KeyboardButton } from '@/components/common/KeyboardButton';
import { ROUTE_PATHS } from '@/router/constants';
import { ListItemTextStyled } from '@/components/Navbar/UserMenu';
import { hasPermissionsToElement } from '@/components/PermissionsChecker/utils';
import { RolePermissionsContext } from '@/contexts/rolePermissions/context';

interface SuperAdminViewMenuItemProps {
  onClick: (action: VoidFunction) => void;
}

export const SuperAdminViewMenuItem = ({
  onClick,
}: SuperAdminViewMenuItemProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { permissions } = useContext(RolePermissionsContext);
  const hasAccessToSuperAdminView = hasPermissionsToElement(
    'SuperAdminPage',
    permissions,
  );

  const isSuperAdminView = useMemo(
    () => pathname === ROUTE_PATHS.SUPER_ADMIN,
    [pathname],
  );
  const goToSuperAdminView = () => {
    if (!isSuperAdminView && hasAccessToSuperAdminView) {
      navigate(ROUTE_PATHS.SUPER_ADMIN);
    } else {
      navigate(ROUTE_PATHS.HOME);
    }
  };
  return (
    <MenuItem divider onClick={() => onClick(goToSuperAdminView)}>
      <ListItemIcon>
        {isSuperAdminView || !hasAccessToSuperAdminView ? (
          <HomeIcon />
        ) : (
          <AdminPanelSettingsIcon />
        )}
      </ListItemIcon>
      <ListItemTextStyled>
        {isSuperAdminView || !hasAccessToSuperAdminView
          ? DICTIONARY.COMMON.HOME
          : DICTIONARY.COMMON.SUPER_ADMIN_VIEW}{' '}
        <KeyboardButton theKey='v' />
      </ListItemTextStyled>
    </MenuItem>
  );
};
