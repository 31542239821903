import { Typography } from '@mui/material';
import { SlaBreachSummary } from '@/service/types';
import { COMMON_STRING } from '@/constants/dictionary';
import { MISTAKES_SHORTCUTS } from '../ActionEvaluationDialog/constants';
import { CustomTooltip } from '../StatsCompareTable/StyledStatsComponents';

export interface MistakeCellProps {
  slaBreachSummary?: SlaBreachSummary | null;
}

export const MistakeCell = ({ slaBreachSummary }: MistakeCellProps) => {
  if (!slaBreachSummary) return null;

  const evaluationTitle = (
    <>
      {COMMON_STRING.MISTAKE}: {slaBreachSummary?.evaluationReason}
      <br />
      {slaBreachSummary?.evaluationReasonNote &&
        `${COMMON_STRING.REASON}: ${slaBreachSummary?.evaluationReasonNote}`}
    </>
  );

  return (
    <CustomTooltip
      placement='top-end'
      sx={{ zIndex: 0 }}
      title={evaluationTitle}
    >
      <Typography
        aria-label='mistake-cell'
        sx={{
          width: '1.5rem',
          height: '1.5rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: (theme) => theme.spacing(0.5),
          fontSize: (theme) => theme.spacing(1.4),
          bgcolor: (theme) => theme.palette.slaMistakeBg,
          color: (theme) => theme.palette.common.black,
        }}
      >
        {
          MISTAKES_SHORTCUTS[
            slaBreachSummary?.evaluationReason as keyof typeof MISTAKES_SHORTCUTS
          ]
        }
      </Typography>
    </CustomTooltip>
  );
};
