import { Collapse, Grid, Stack, TableCell, TableRow } from '@mui/material';
import {
  Collection,
  IssueSeverity,
} from '@/contexts/fixturesWithIncidents/types';
import { SectionTitleTypography } from '@/components/Preferences/PreferencesTypography';
import { COMMON_STRING } from '@/constants/dictionary';
import { InformationRow } from '@/components/common/InformationRow';
import { RankWithIcon } from '@/components/common/RankWithIcon';
import { FixturesDetailsGridItem } from '@/components/FixturesTable/common/FixturesDetailsGridItem';
import { formatDate } from '@/utils/formatDate/formatDate';
import { TIME_FORMAT } from '@/constants/dateFormats';
import { TimeSinceReported } from '@/components/FixturesTable/common/TimeSinceReported';

interface FixturesTableRowDetailsProps {
  collection: Collection;
  opened: boolean;
}

export const FixturesTableRowDetails = ({
  collection,
  opened,
}: FixturesTableRowDetailsProps) => {
  const issueWithMainAccident = collection.issues.find(
    (issue) => issue.requiresAction.id !== IssueSeverity.None,
  );

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}>
        <Collapse in={opened} timeout='auto' unmountOnExit>
          <Stack gap={2} padding={({ spacing }) => spacing(1, 4.5, 2)}>
            <SectionTitleTypography>
              {COMMON_STRING.DETAILS}
            </SectionTitleTypography>
            <Grid container spacing={2}>
              <FixturesDetailsGridItem>
                <InformationRow label={COMMON_STRING.TIME_SINCE_REPORTED}>
                  <TimeSinceReported
                    issueWithMainIncident={issueWithMainAccident}
                  />
                </InformationRow>
                <InformationRow
                  label={COMMON_STRING.INCIDENT_RESOLVER}
                  description={collection.resolver?.fullName}
                />
                <InformationRow
                  label={COMMON_STRING.RESOULTION_TIME}
                  description={
                    collection.resolver?.resolvedOn
                      ? formatDate(
                          collection.resolver.resolvedOn,
                          TIME_FORMAT.TIME_ZONE_FORMAT,
                        )
                      : undefined
                  }
                />
              </FixturesDetailsGridItem>
              <FixturesDetailsGridItem>
                <InformationRow label={COMMON_STRING.RANK}>
                  <RankWithIcon rank={collection.rank} />
                </InformationRow>
                <InformationRow
                  label={COMMON_STRING.COMPETITION}
                  description={collection.competitionName}
                />
                <InformationRow
                  label={COMMON_STRING.SUPERVISOR_NAME}
                  description={collection.supervisorName}
                />
                <InformationRow
                  label={COMMON_STRING.WORKSTATION}
                  description={collection.workstationName}
                />
              </FixturesDetailsGridItem>
              {collection.scorers.map((scorer) => (
                <FixturesDetailsGridItem
                  gridProps={{ xs: 3 }}
                  key={scorer.userId}
                >
                  <InformationRow
                    label={COMMON_STRING.SCORER_NAME}
                    description={scorer.fullName}
                  />
                  <InformationRow
                    label={COMMON_STRING.APP_VERSION}
                    description={scorer.appVersion}
                  />
                </FixturesDetailsGridItem>
              ))}
            </Grid>
          </Stack>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
